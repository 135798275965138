import { ViteSSG } from "vite-ssg"
import { routes } from "vue-router/auto/routes"
import { setupLayouts } from "virtual:generated-layouts"
import App from "./App.vue"

import "@unocss/reset/tailwind.css"
import "./styles/main.css"
import "uno.css"

// https://github.com/antfu/vite-ssg
export const createApp = ViteSSG(App, {
  routes: setupLayouts(routes),
  base: import.meta.env.BASE_URL,
}, (ctx) => {
  const storedLanguage = localStorage.getItem("userLanguage")
  const initialLanguage = storedLanguage || "en"
  document.documentElement.lang = initialLanguage
  // install all modules under `modules/`
  const modules = import.meta.glob("./modules/*.ts", { eager: true })
  Object.values(modules).forEach(i => i.install?.(ctx))
})
