import { defineStore } from "pinia"
import type { Automation } from "@bonx/common"
import { AutomationService } from "@bonx/common"
import { omit } from "lodash-es"
import DBHelper from "../helpers/dbHelper"
import { userStore } from "./user"
import { globalStore } from "./global"

const dbHelper = new DBHelper()

export type AutomationStep = Record<string, string> & {
  type: string
  name: string
}

export interface CreateAutomation {
  name: string
}

export const automationsStore = defineStore({
  id: "automations",
  state: () => ({
    automations: {
      list: [] as Array<Automation>,
    },
    _automationService: null as AutomationService | null,
  }),
  getters: {
    getAutomations: state => () => {
      return state.automations.list
    },
    getAutomationService: state => () => {
      if (!state._automationService)
        state._automationService = new AutomationService(dbHelper, userStore().user.client_id)

      return state._automationService
    },
  },
  actions: {
    async loadAutomations(): Promise<void> {
      this.automations.list = await this.getAutomationService().list()
    },
    async createAutomation(data: CreateAutomation): Promise<Automation | null> {
      const user = userStore().user
      if (user) {
        const automation: Partial<Automation> = {
          ...data,
          client_id: user.client_id,
          steps: [],
          trigger: {
            type: "manual",
          },
        }
        const firebaseAutomation = await this.getAutomationService().create(automation)
        automation.id = firebaseAutomation.id
        this.automations.list.push(automation as Automation)
        return automation as Automation
      }
      return null
    },
    async updateAutomation(automationId: string, automation: Automation) {
      // Make sure we don't send any fields that are not allowed
      const input = omit(automation, ["id", "created_at", "updated_at"])
      await this.getAutomationService().update(automationId, input)
      await this.loadAutomations()
      await globalStore().updateStoreCache()
    },

    /**
     * Fetches automation from raw DB instead of store cache
     * @param automationId
     */
    async getById(automationId: string) {
      const user = userStore().user
      if (!user?.client_id)
        throw new Error("Could not find user")

      return await this.getAutomationService().get(automationId)
    },
  },
})
