automation:
  action: Action
  add_step: Add step
  add_trigger: Add a trigger
  create_new: New automation
  disabled_automations: Disabled automations
  disable_automation: Disable automation
  enabled_automations: Enabled automations
  enable_automation: Enable automation
  error_launch: Automation launch has failed
  is_disabled: Automation is disabled
  select_trigger_description: Select a trigger in order to start configuring your automation
  success_launch: Automation launch was successfully completed
  trigger: Déclencheur
  trigger_api: When an api call is received
  trigger_graphquery: When a graphQL query is executed
  trigger_transformation: When an object is edited
automations:
  automation_name_placeholder: Automation name
  create_automation: Create a new automation
  creation_automation_name: Automation name
  modal_new_automation_description: >-
    Automations allow you create automated processes in order to create, edit
    and save data into Bonx.
  run:
    datetime: Run date
    id: Run id
  step:
    end_datetime: Step end date
    name: Step name
    start_datetime: Step start date
  tab_credentials: Credentials
  tab_detail: Automatisation
  tab_history: History
blocks:
  action_button_primary: Primary
  action_button_secondary: Secondary
  action_button_visible: Shown
  action_buttons: Action buttons
  action_condition:
    key: Key
    value: Value
  action_goto:
    url: URL
  action_open_dialog:
    content: Content
    icon: Icon
    node_id: Object ID
    show_object_card: Show object card
    title: Title
  action_open_drawer:
    mapping: Mapping
    model_id: Model ID
    nodeid: Object ID
    type: Action
    type_create: Create an object
    type_edit: Edit an object
  action_open_form:
    query: Query params
  action_update_object:
    model_id: Model ID
    nodeid: Object ID
    object: Object
  actions:
    goto: Go to
    hide: Hide
    label: Actions
    open_dialog: Open dialog
    open_form: Open form
    refresh_block: Reload block
    show: Show
    target: Trigger target
    trigger: Trigger
    update_object: Update Object
    open_drawer: Open Drawer
    delete_object: Delete Object
    callback: Callback
  axis:
    aggregation: Aggregation
    aggregation_avg: Average
    aggregation_collect: Values
    aggregation_collectUnique: Unique values
    aggregation_count: Count
    aggregation_countUnique: Count unique
    aggregation_max: Maximum
    aggregation_median: Median
    aggregation_min: Minimum
    aggregation_sum: Sum
    aggregation_truncateDay: Truncate to day
    aggregation_truncateHour: Truncate to hour
    aggregation_truncateMonth: Truncate to month
    aggregation_truncateWeek: Truncate to week
    aggregation_truncateYear: Truncate to year
    autofill: Add all
    config_error_message: Some fields are empty. Please fill them before saving
    default_value: Default value
    file_display_image: Display as image
    formatting: Conditional formatting
    formatting_comparator_color_from_tags: Color from tags
    formatting_comparator_label: Comparator
    formatting_target_background: Background
    formatting_target_content: Text
    formatting_target_label: Target property
    id: ID
    label: Axis
    progression_bar_max: Max
    progression_bar_min: Min
    select_custom: Custom values
    select_dataset: Dataset
    tags_custom: Custom values
    tags_dataset: Dataset attribute
    tags_options: Options
    tags_source: Options source
    type_boolean: Boolean
    type_date: Date
    type_datetime: DateTime
    type_duration: Duration
    type_file: File
    type_number: Number
    type_select: Select
    type_tags: Tags
    type_text: Text
    value: Value
  calendar_event_default_duration: Default duration (hours)
  calendar_hidden_days: Hidden days
  calendar_slot_max_time: End time of days
  calendar_slot_min_time: Start time of days
  chart:
    type: Chart type
    type_bar: Bar
    type_doughnut: Doughnut
    type_hist: Histogram
    type_line: Line
  className: Style
  columns: Columns count
  comparators:
    type_empty: is empty
    type_eq: equality
    type_gt: greater than
    type_gte: greater than or equal to
    type_include: contain
    type_ineq: inequality
    type_lt: less than
    type_lte: less than or equal to
    type_not_empty: is not empty
    type_not_include: does not contain
    type_notnull: is not NULL
    type_null: is NULL
  dataset:
    block: Block
    custom: Query
    label: Dataset
    model: Model
    type: Type
  edit_mode:
    activate: Enable edit mode
    desactivate: Disable edit mode
  elementId: Element ID
  elementsPerPage: Elements per page
  empty_block_description: You haven't yet finalized the configuration of this block.
  empty_layout_description: Add a new block
  filter_comparators:
    contains: contains
    equal: equal to
    greaterthan: greater than
    greaterthanorequal: greater than or equal
    isempty: empty
    isin: in
    isnotempty: not empty
    isnotin: not in
    isrelativetotoday: relative to today
    lessthan: less than
    lessthanorequal: less than or equal
    notcontains: does not contain
    notequal: not equal to
  filters: Filters
  filters_column: Colonne
  formula: Formula
  invalid_filter: Invalid filter
  limit: Limit
  metrics_name: Metrics name
  new_block: My new block
  ordering: Ordering
  ordering_asc: ASC
  ordering_desc: DESC
  playbooks:
    scannedQrCode: Scanned Qr Code
    actionButtonClick: Action Button Click
    rowClick: Row Click
    cellClick: Cell Click
    selectedRows: Selected Rows
    dateClick: Date click
    rowChanged: Row Changed
  qrCodeFullSize: QR Code button in full screen mode
  quit_confirmation_body: Some changes have not been saved. Are you sure you want to quit ?
  searchable: Searchable
  select_disable: Unable to select because no id
  selectable: Selectable
  selection: Selection
  show_title: Show title
  skip: Skip
  sortable: Sortable
  types:
    calendar: Calendar
    cardlist: Card list
    chart: Chart
    dataset: Dataset
    gallery: Gallery
    gantt: Gantt
    inputs: Inputs
    layout: Layout
    metrics: Metrics
    table: Table
button:
  about: About
  back: Back
  copy_data_to_clipboard: Copy data
  download_data: Download data
  permission_denied: You don't have the permission to activate this button.
  toggle_dark: Dark mode
  toggle_langs: Toggle language
dashboard:
  last_30_days: Last 30 days
data_connection:
  action: Action
  add_step: Add step
  api_rest: Read from / Push data to a (3rd party) API
  auth: Auth method
  auth_basic: basic
  auth_cookie: Cookie
  auth_metabase: Metabase
  auth_oauth2: oAuth2
  auth_oauth2_authorization_endpoint: Authorization Endpoint
  auth_oauth2_client_id: Client ID
  auth_oauth2_client_secret: Client Secret
  auth_oauth2_connect: Se connecter
  auth_oauth2_scope: Scope
  auth_oauth2_token_endpoint: Token Endpoint
  credential: Credential
  credential_json_edition_label: Edit the credential json
  csv: Generate a CSV
  csv_bucket: Bucket
  csv_custom_cleanup: Cleanup script
  csv_file_name: File name
  csv_file_path: File path
  csv_sub_key_input_data: Input data subkey
  data_changed: A value changed on a model
  data_connection_name_placeholder: My data connection
  data_connection_step_type_placeholder: Data connection step type place holder
  data_connection_steps: Step detail
  document_url: Google sheet shared url
  email: Send an email
  email_template_name: Template name
  force_use_input_data: Force use input data
  form_sent: A form has been sent
  google_sheet: Import from / Export to Google Sheet
  graph_query: Execute code
  template: Action
  gsheet_action: Action
  gsheet_action_read: Read
  gsheet_action_write: Write
  gsheet_clear_sheet: Clear sheet
  input_data: Input variable
  json_edition_label: Edit
  load_file: Load file
  manual_launch: Manual Launch
  model_choice: Model choice
  name: Step name
  password: Password
  query: Query
  save_file: Save file
  save_output: Output variable
  soft_fail: Soft fail
  step_json_edition_label: Edit the step json
  stop: End the automation
  tab: Selected google sheet tab
  url: Api Url
  username: Username
  webhook: WebHook
  webhook_url: Webhook URL
  read_query: Read query
  path: Transformation path
day: Day
days:
  friday: Friday
  monday: Monday
  saturday: Saturday
  sunday: Sunday
  thursday: Thursday
  tuesday: Tuesday
  wednesday: Wednesday
email:
  body: Email body
  from_name: From name
  subject: Subject email
error:
  could_not_export_pdf_template: Impossible to export pdf template
  could_not_share_url: Impossible to generate form share url
filters:
  date_indicator_next: Next
  date_indicator_past: Past
  date_indicator_this: This
  date_relative_unit_days: Day(s)
  date_relative_unit_hours: Hour(s)
  date_relative_unit_months: Month(s)
  date_relative_unit_weeks: Week(s)
  date_relative_unit_years: Year(s)
  filter: Filter
  formula: Formula mode
  lock: Lock
  new_filter: New filter
  operators_doesnt_include: Doesn't contain
  operators_equals_to: (==) equal to
  operators_greater_than: (>) greater than
  operators_greater_than_or_equal_to: (>=) greater than or equal to
  operators_includes: Contains
  operators_is_empty: is empty
  operators_is_not_empty: is not empty
  operators_is_relative_to_today: Relative to today
  operators_less_than: (<) less than
  operators_less_than_or_equal_to: (<=) lass than or equal to
  operators_no_operator: No condition
  operators_not_equal_to: (!=) not equal to
  reset: Reset
  save_for_all: Save for all
  simple: Simple mode
  unlock: Unlock
form:
  confirm_before_sending: Please review your choices before submitting the form.
  sent_recap: Summary
  serial_not connected: >-
    Link is not active. Please click on the "chain" icon to start the data link
    with the serial device.
  serial_supported: Your browser does not support serial. Please use Chrome or Edge.
form.dollar_helper: >-
  Variables preceded by a "$" sign are internal to the form. These variable must
  then be linked to the model attributes bellow.
form_field_max_value: Max value
forms:
  action_delete_form: Delete form
  action_dupplicate_form: Duplicate Form
  add_field_table: Add a field or a table
  already_used_share_token: This form has already been submitted
  autofill: Autofill
  autofill_end: >-
    This field is filled automatically by Bonx when the form is submitted, using
    the current date and time.
  autofill_form_ended_datetime: Form ended datetime
  autofill_form_started_datetime: Form started datetime
  autofill_information: This field is automatically filled by Bonx
  autofill_scan: Scanned qrcode value
  autofill_scan_datetime: Datetime of the scan
  autofill_start: >-
    This field is filled automatically by Bonx when the form is opened, using
    the current date and time.
  base_model: Base model
  create_form: Create form
  create_new: Create a new form
  creation_form_name: Form name
  creation_related_process_end_step: Next step
  creation_related_process_start_step: Start step
  deletion_confirmation_body: >-
    Are you sure you want to delete this form? Only a Bonx administrator will be
    able to restaure it in the future.
  deletion_confirmation_title: Form deletion
  edit_no_field_warning: This form has no field
  edition_mode: Edition mode
  error_save_not_admin: Only an administrator can save an editted form
  field_multiple_answers_allowed: You can select multiple options
  field_no_options: There are no options available yet.
  field_number: Field number
  field_settings: Field settings
  fields_tables: Fields
  file:
    compact: Compact
    viewer: Viewer
  foo: bar
  form_field_aql_number_of_elements_to_control: "# of elements to control"
  form_field_aql_number_of_max_non_quality: "# of max non quality"
  form_field_aql_number_of_defects: "# of defects"
  form_field_aql_tolerance_percent: "Toleration (%) :"
  form_field_aql_elo: "ELO:"
  form_field_aql_aql: "AQL:"
  form_field_autocomplete: Autocomplete
  form_field_configuration: Configuration
  form_field_date: Date
  form_field_date_picker_type: Date type
  form_field_date_picker_type_date: Date
  form_field_date_picker_type_datetime: Datetime
  form_field_display: Display
  form_field_display_file: Display as a file
  form_field_display_image: Display as image
  form_field_display_readquery_placeholder: Add a secondary reading query (optional)
  form_field_display_type: Type
  form_field_display_type_default: Default
  form_field_display_type_file: File
  form_field_display_type_image: Image
  form_field_document: Document
  form_field_file_no_assets: No file to display
  form_field_formula_attributes_label: Attributes
  form_field_formula_context: View context
  form_field_formula_fixed_label: Fixed
  form_field_formula_formula_label: Formula
  form_field_formula_functions_avg: Average
  form_field_formula_functions_ceil: Ceil
  form_field_formula_functions_coalesce: Coalesce
  form_field_formula_functions_collect: Collect
  form_field_formula_functions_collectUnique: UniqueCollecte
  form_field_formula_functions_count: Count
  form_field_formula_functions_countUnique: UniqueCount
  form_field_formula_functions_date: Date
  form_field_formula_functions_dateadd: DateAdd
  form_field_formula_functions_datesub: DateSub
  form_field_formula_functions_durationInDays: DurationInDays
  form_field_formula_functions_durationInSeconds: DurationInSeconds
  form_field_formula_functions_durationbetween: DurationBetween
  form_field_formula_functions_float: ToFloat
  form_field_formula_functions_floor: Floor
  form_field_formula_functions_formatDate: formatDate
  form_field_formula_functions_head: First
  form_field_formula_functions_id: ID
  form_field_formula_functions_if: If
  form_field_formula_functions_label: Functions
  form_field_formula_functions_last: Last
  form_field_formula_functions_max: Max
  form_field_formula_functions_min: min
  form_field_formula_functions_now: Now
  form_field_formula_functions_right: Right
  form_field_formula_functions_round: Round
  form_field_formula_functions_sqrt: SquareRoot
  form_field_formula_functions_sum: Sum
  form_field_formula_functions_tags: Tags
  form_field_formula_functions_ifEmpty: IfEmpty
  form_field_formula_identity: Identity
  form_field_formula_invalid_label: Invalid formula
  form_field_formula_valid_label: Valid formula
  form_field_has_min_max_values: Has min max values
  form_field_image: Image
  form_field_items: Items
  form_field_items_allow_creation: Allow creation
  form_field_items_allow_deletion: Allow deletion
  form_field_items_columns: Columns
  form_field_items_subtables: Subtables
  form_field_items_new_col: New column
  form_field_label_label: Field name
  form_field_min_value: Min value
  form_field_model_attribute: Attribute
  form_field_multiple_choice: Multiple choices
  form_field_name_label: Attribute key
  form_field_number: Number
  form_field_option: Option
  form_field_option_required: >-
    An option field is required by default, please select the "Multiple choices"
    field if an empty value can be returned by the form.
  form_field_options_label: List of choices
  form_field_options_placeholder: Add a choice
  form_field_printable_form: Print
  form_field_read_query: Read query
  form_field_relation_allow_view: Allow view
  form_field_relation_displayed_field: Displayed field
  form_field_required_label: Mandatory
  form_field_template: Template
  form_field_text: Text field
  form_field_text_description_placeholder: Add a description or a helper here (optional)
  form_field_text_label_placeholder: Add a field name
  form_field_text_name_placeholder: Add an attribute key
  form_field_text_value_placeholder: Add a default value (optional)
  form_field_textarea_label: Long text
  form_field_title: You are now seeing how this form would look like on
  form_field_write_query: Write query
  form_fields: Forms fields
  form_readquery_placeholder: Add a primary reading query (optional)
  form_select_attribute: Select an attribute
  form_select_object: Select an object
  form_title_placeholder: Form name
  form_writequery_placeholder: Add a write query
  formreadquery_placeholder: Add a primary reading query (optional)
  full_screen: Full screen (full width)
  hide_zero: Hide Zero
  initial_value: Initial value
  label_template : Label template
  legacy: Legacy
  loading_error_message: Error occurred while loading the from
  modal_new_form_description: >-
    The form creator allows you to generate forms to be completed by your teams
    and therefore automates data feedback.
  modal_share_form_description: You can share this form by email with a unique link and publicly accessible.
  no_related_model_found: No model found
  please_select_model_attribute: Please select a model attribute
  postflight_summary: Display summary after sending
  prefill: Pre-fill
  preflight_summary: Request confirmation before sending
  printer: Printer
  printer_not_supported: Dymo printer is not supported.
  qcmct_title: Quality control
  quantity: Quantity
  read_only: Read only
  rendering: Rendering document...
  selected_test_object: Selected Test Object
  share: Share
  share_form_emails_label: Email addresses
  state_recovered: Form data has been recovered from a previous session
  toast_deletion_success: This form has been successfully deleted.
  toast_duplication_success: >-
    The form has been successfully duplicated. You are getting redirected to
    this new form.
  update_success: This form has been updated successfully
forms.choose_attribute: Choose attribute
forms.creation_import_fields: Import model attributes
forms.creation_related_model: Related Model
forms.creation_related_process: Related Process
forms.creation_related_process_step: Related Process Step
forms.form_field_no_related_model_placeholder: No related model
forms.form_field_no_relationship_placeholder: No relationship
forms.form_field_relation: Relationship
forms.form_field_relationship_allow_existing_object_label: Allow linkage of an existing object
forms.form_field_relationship_allow_new_object_label: Allow the creation of a new linked item
forms.form_field_relationship_related_model_label: Select a related model
forms.form_field_relationship_value_label: Select a relationship
forms.form_share: Publicly shared form
forms.labels: Labels
forms.new_form_advanced: Advanced
forms.new_form_classic: Classic
forms.no_model: This form is not linked to an existing model
forms.no_related_step: Not linked to a process step
global:
  actions: Actions
  add: Add
  agree: "Yes"
  automations: Automations
  cancel: Cancel
  click_button_reload: Please click on the button bellow to reload !
  client: Company
  client_name: Client name
  clients: Clients
  close: Close
  collaboration: collaboration
  comment: Commentaire
  compare: Compare
  condition: Condition
  confirm: Confirm
  create: Create
  create_variable: Create a variable
  currency: Currency
  dashboard: Dashboard
  data: Data
  data_connections: Data Connections
  data_saved: Your data has been saved
  not_permitted: You don't have the permission to perform this action
  days: Days
  delete: Delete
  description: Description
  details: Details
  disabled: Disabled
  disabled_users: Desactivated users
  display: Display
  display_style: Display type
  download_document: Download document
  duplicate: Dupplicate
  duration: Duration
  edit: Edit
  email: Email
  end_of: End of
  entity: Entity
  error: Error
  error_json: JSON is incorrect
  error_login_invalid_password: Incorrect password.
  error_login_invalid_user: User not found, please double check the username.
  error_login_unknown: >-
    Unknown logging error, please reach out to Bonx team using the round button
    at the bottom right of this screen.
  error_password_validation: >-
    Your password must be at least 8 characters long, and must contain at least
    one lowercase, one uppercase and a number.
  error_space_in_name: The field must not contain any spaces
  error_trailing_underscore: The name must not end by an underscore (_)
  failed: Failed
  "false": "False"
  field: field
  fields: fields
  file_input:
    existing_file: "Existing file :"
    no_file: You have submitted no file yet
    replace: (will be replaced)
  filter: Filter
  first_name: First name
  follow_up: Follow Up
  forgot_password: Forgot password
  form: Form
  forms: Forms
  from: From
  group: Group
  group_order: N°(order)
  groups: Groups
  home: Home
  icon: Icon
  id: Id
  informations: Informations
  is_hidden: Hide this attribute
  is_required: Required
  are_required: None is required | {fields} is required | {fields} are required
  items: Items
  key: Key
  label: Label
  labels: label | label | labels
  large: Large
  last_name: Last name
  launch: Launch
  launch_date: Launch date
  list: List
  loading: Loading...
  logic: Logic
  login: Login
  logout: Logout
  medium: Medium
  missing_field: No missing field | Missing field | {n} missing fields
  missing_write_request: You did not provide a write request
  models: model | model | models
  modified: modified
  month:
    april_short: apr
    august_short: aug
    december_short: dec
    february_short: feb
    january_short: jan
    july_short: jul
    june_short: jun
    march_short: mar
    may_short: may
    november_short: nov
    october_short: oct
    september_short: sep
  name: Name
  new: New
  new_version: A new version is available!
  "no": "No"
  no_entries: No entries
  no_results: No results found
  none: None
  not-found: Not found
  objects: Object | Object | Objects
  ok: Ok
  order: order
  overview: Overview
  parameters: Parameters
  password: Password
  pdf_template: PDF template
  permissions: Permissions
  phone: Phone
  photo: Photo
  photo_url: Url photo
  process: Process
  processes: Processes
  project: Project
  projects: Projects
  reload: Reload
  remember_me: Remember me
  replay: Replay
  reset_email_sent: >-
    An email to reset your password has been sent. Please click on the link when
    you get it
  reset_password: Reset Password
  role: Role
  rows: rows
  save: Save
  saving: Saving...
  search: Search
  select: Select
  send: Send
  send_reset_email: Send reset email
  settings: Settings
  show: Show
  small: Small
  status: Status
  success: Success
  table:
    nav: Table pagination
  task: Task | Task | Tasks
  title: Title
  total_report: Total report
  training_and_help: Ressource center
  "true": "True"
  type: Type
  unknown_user: Unknown user
  unsinchronized_store_header_content: >-
    This following element has been updated. Therefore, you will not be allowed
    to save any edition before you refresh the page
  update: Update
  user: User
  users: Users
  users_and_permissions: Users and permissions
  validate: Validate
  value: Value
  variables: Variables
  views: Views
  week: Week
  "yes": "Yes"
  you_dont_have_access_to_this_page: You don't have access to this page
global.attribute: Attribute
global.close: Close
global.instance: Instance
global.model: Model
global.toast_error_copy_to_clipboard: Error occurred while copying data
global.toast_export_data_error: Error occurred while exporting data
global.toast_key_copy_success_content: The key has been succesfully copied in your clipboard.
global.toast_succes_copy_to_clipboard: The table content has been successfully copied to your clipboard
global.toast_succes_copy_to_clipboard_link: The link has been successfully copied to your clipboard
global.unlink: Unlink
global.unsinchronized_store_header_content: >-
  The following element has been updated. Therefore, you will not be allowed to
  save any edition before you refresh the page.
global.unsinchronized_store_header_content_pt1: The following elements have been updated
global.unsinchronized_store_header_content_pt2: >-
  Therefore, you will not be allowed perform any edition before you refresh the
  page
global.unsinchronized_store_header_title: Bonx configuration has been edited by another user
global.update: Update
groups:
  group_delete_description: "You're going to delete %{group_name}\_. Are you sure ?"
  group_name: Group name
  group_order: Group order
  groups_list: Groups list
  toast_edit_success: Changes saved to the group have been saved successfully
home:
  greetings: Greetings
integrations:
  login_flow_not_implemented: >-
    Integration login settings for this can only be edited by a Bonx
    administrator.
  oauth_login: OAuth login
  referenced_resources: Referenced resources
  title: Integrations
  type: Integration type
list: List
messaging:
  add_to: Add a recipient (email or phone number)
  body: Body
  email: Email
  external: This message will be sent to people outside your organization
  messaging: Messaging
  placeholder: Ask a question or send an update
  send: Send
  sent_message: sent a message
  sms: SMS
  somebody: Somebody
  subject: Subject
  template: |-
    Message template |
    Message template |
    Message templates
  user_firstname: User's firstname
  user_lastname: User's lastname
  variables: Variables
  whatsapp: WhatsApp
modal_confirmation_popup_cancel: No.
modal_confirmation_popup_confirm: Yes.
month: Month
object:
  also_delete_related_object: Also delete definitively the related object
  attribute:
    attributes: attribute | attribute | attributes
  attributes: Object attributes
  attributes_list: Attributes list
  cancel_changes: Cancel changes
  cancel_changes_description: "You want to undo the changes made. Confirm\_?"
  create_object: Create
  create_related_object: Create a new object
  delete_object: Delete the object
  delete_object_attribute: Delete an attribute
  delete_object_attribute_description: "The following attribute will be deleted. \nThis deletion is permanent. \nConfirm\_?"
  delete_object_description: "This object will be deleted. \nThis operation is permanent. \nConfirm\_?"
  delete_related_object: Unlink related object
  deletion_confirmation_body: Are you sure to unlink these objects ?
  error_attribute_with_same_name_exists: >-
    An existing or deleted attribute already has the same name, please contact
    your IT department or a Bonx administrator for more information.
  error_loading: There was an error while loading this page's content
  error_no_primary_attribute: No primary attribute has been defined for this model
  link_related_object: Link an existing object
  related_objects: Related object | Related object | Related objects
  relation:
    attributes_list: Relationship attributes list
  relation_linked_to: Restrict linked objects to
  relation_not_found: Relation not found
  relation_sort_asc: Ascending
  relation_sort_desc: Descending
  relation_sort_model_as: Show "{model}" according to sorting
  relation_sort_unset: Unset
  relationship:
    many_to_many: N:N
    many_to_one: N:1
    object: Linked object
    one_to_many: 1:N
    one_to_one: "1:1"
    to_object: To the object
  relationship_list: Relationships List
  relationships: relationship | relationship | relationships
  search_related_objects: Search for a related object
  selected_setting_object: Object Type
  show_details: Show details
  timeline: Timeline
objects:
  add_attribute: Add an attribute
  add_label: Create a label
  add_object: Create an object
  attributes_subtype_float: Float
  attributes_subtype_integer: Integer
  attributes_subtype_link: Link
  attributes_subtype_plain_text: Plain text
  attributes_type_boolean: Boolean
  attributes_type_date: Date
  attributes_type_datetime: Datetime
  attributes_type_file: File
  attributes_type_multitag: Multitags
  attributes_type_number: Number
  attributes_type_progress: Progress
  attributes_type_relation: Relation
  attributes_type_tag: Tag
  attributes_type_text: Text
  attributes_type_textarea: Textarea
  data_model: Data Model
  data_models: Data models
  delete_data_model: Delete data model
  delete_data_model_description: "This data model '%{attribute_name}' will be deleted. \nThis operation is permanent. \nConfirm\_?"
  delete_objects: Delete objects
  delete_objects_description: "The %{count} following objects will be deleted. \nThis operation is permanent. \nConfirm\_?"
  delete_relation: Delete link
  displayed_name: Displayed name
  edit_attribute: Edit an attribute
  edit_label: Edit a label
  edit_object: Edit an object
  label:
    placeholder: Please choose a label
  label_objects: Objects linked to this label
  label_relations: Relations linked to this label
  labels: Labels
  labels_list: Labels list
  models: Models
  models_list: Model lists
  objects_list: Objects list
  overview: overview
  relation:
    add_relation: Add a relationship
    edit_relation: Edit a relationship
  relations: Relations
otable:
  error:
    table_has_no_column: >-
      This table will be visible as soon as a key will be added as a column,
      please contact an administator from your company.
pages:
  automation: Automation
  data-connection: Data connection
  object: Objects
pdf_template:
  create_template: Create a template
  id_error: You must provide a pdf template id
  model: Model
  no_template: No PDF template for this type of object
  pdf_export: Export PDF
process.finished: Finished
process.in_progress: In progress
process.waiting: Waiting
processes:
  next_step: Next step
  save_first: Save the process first to be able to add forms
  step_name: Step name
profile:
  gantt_size_label: Gantt chart display
  home_page: Home page
  my_profile: My profile
  settings: Settings
settings:
  actions:
    create: Create (global)
    create_own: Create
    delete: Delete (global)
    delete_own: Delete
    read: Read (global)
    read_own: Read
    update: Update (global)
    update_own: Update
  parent: Inherit from
  permissions:
    action: Action
    allow: Allow
    calculated: Calculated
    conflict: Conflict
    deny: Deny
    info_1: >-
      Les modifications des droits ne s'appliquent qu'à ce groupe et aux groupes
      enfants.
    info_2: Hérité signifie que les droits du groupe parent seront utilisés.
    info_3: >-
      Refusé signifie que quels que soient les droits du groupe parent, le
      groupe concerné ne pourra pas effectuer cette action.
    info_4: >-
      Autorisé signifie que le groupe concerné pourra effectuer cette action ;
      s'il y a conflit avec le groupe parent, la modification ne sera pas
      appliquée, le label Non autorisé (verrouillé) sera affiché dans la colonne
      'Droits appliqués'
    info_5: >-
      Non défini n'est utilisé que pour le groupe 'Invité'. Le groupe 'Invité'
      est le parent de tous les groupes. Si un droit n'est pas défini, il sera
      traité comme 'Refusé' mais peut être changé pour les groupes enfants,
      composants, catégories et éléments.
    inherit: Inherited
    not_set: Not set
    permission: Permission
    resource: Resource
  resources:
    clients: Customers
    forms: Forms
    messagetemplates: Message templates
    settingdataconnections: Automations
    settings: Settings
    settingsmodels: Models
    users: Users
    viewgroups: View groups
    views: View
  roles:
    admin: Super Administrator
    client_admin: Administrator
    guest: Guest
    user: User
timeline:
  a_customer: A customer
  edit_value: edited value
  external_user: External user
  form_submitted: submitted a form
  commented: commented
  comments: Comments
  addComment: Add a comment
  messages: Messages
  new_value: New value
  no_events: No events for this object
  old_value: Old value
toast:
  delete_failure: The object or objects could not be deleted.
  delete_objects: The objects has been deleted successfully
  form_submit_content: The form has been successfully submitted
  print_success: A page will open soon
  share_form_and_copy_url_success: Unique form URL has been copied into the clipboard
  view_error_no_model_title: Bonx will soon allow you to edit complex views
  view_error_no_model_title_content: >-
    This view has been manually built, please reach out for your IT department
    or a Bonx administrator.
toast.form_field_file_submit_content_error: This form requires a file to be submitted.
today: Today
user:
  add_label: Create a label
  data_model:
    labels: Labels
    models: Models
    relations: Relationships
  data_models: Data models
  edit_label: Edit a label
  label:
    placeholder: Find a label ...
  models_list: Labels list
view:
  action_move_up: Move up
  config_error_message: >-
    Your view does not contain any attributes. Please configure it by clicking
    on the button bellow.
  config_view: Configure the view
  edit_view: Edit
  no_title: No title
  toast_edit_success: The view detail has been succesfully edited
views:
  action_move_down: Move down
  add_attribute: New attribute
  add_block: New block
  block_name: Block name
  block_type: Type du block
  blocks: Blocks
  create_new_view: Create a new view
  create_new_view_description: >-
    Views allow you to visualize the data you are interested in, in different
    forms and to share it with your teams.
  create_view: Create a new view
  creation_automation_name_label: Automation name
  creation_form_name_label: Form name
  creation_view_model: Related model
  creation_view_name: Name of the view
  default_setup_element_name: Data related to the model
  default_view_group_name: Default Group
  deletion_confirmation_body: >-
    Are you sure you want to delete this view? All of its data will be stored in
    case you made a mistake and wants to reverse it
  deletion_confirmation_body_soft: Are you sure you want to delete this view?
  deletion_confirmation_title: View deletion
  duration:
    {
      d: "{value} days",
      h: "{value} hours",
      m: "{value} minutes",
      s: "{value} secondes",
    }
  duration_format: Format
  duration_formats:
    d: days
    dhms: days, hours, minutes, seconds
    h: hours
    hms: hours, minutes, seconds
    m: minutes
    ms: minutes, seconds
    s: seconds
  edit_attributes_options: Attributes options
  edit_attributes_options_add_colors: Colors
  edit_attributes_options_add_icon: Icons
  edit_attributes_options_add_status: Status
  edit_attributes_options_background_color: Background color
  edit_attributes_options_border_color: Border color
  edit_attributes_options_description: Selecting an attribute's options allows you to change its deep settings.
  edit_attributes_options_hide_json: Hide details
  edit_attributes_options_icon: Icon
  edit_attributes_options_no_attributes: This block possesses no attribute yet.
  edit_attributes_options_show_json: Show details
  edit_attributes_options_status_error: "Status : error"
  edit_attributes_options_status_success: "Status : success"
  edit_attributes_options_status_warning: "Status : warning"
  edit_import_model: Import a model
  edit_import_model_description: >-
    Importing a model allows you to add in one click all its attributes as axes
    of the block.
  edition_deletion: Remove the view
  edition_no_columns: >-
    This checkboxes will be visible as soon as an attribute will be added to the
    model, please contact an administator from your company.
  edition_no_related_objects: This model is not related to any other model.
  edition_primary_table: Primary data table
  edition_secondary_related_objects: Related objects
  edition_selected_attributes: Attributes
  edition_selected_columns: Columns
  error:
    loading_image: An error occurred while loading images
    query_invalid_or_missing: >-
      The provided resquest is empty or resulted in an error, please reach out
      for your IT department or Bonx.
  filter_label_placeholder: Label to filter
  filter_value_placeholder: Value
  filter_view: Filter this view
  filters_add_filter: Add a condition
  gantt:
    bar:
      no_label: This bar owns no label
    precision:
      day: days
      display_by: Display by
      month: months
  id: Id
  label: Label
  modal_new_view_description: >-
    Views allow you to visualize your data the way you want and to share it with
    yours teams.
  query: GraphQL request
  toast_deletion_success: This view has been successfully deleted.
  toast_edit_success: The view has been succesfully edited
  type: Type
  view_block_name_placeholder: My view block name (optionnal)
  view_group: View group
  view_name_placeholder: View name
  view_parameters_title: ⚙️ View parameters
  views_list: Views list
week: Week
