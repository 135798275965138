/*
This file contains the implementation of a linked list
*/
/**
 * Node class
 * id is the id of the node
 * content is the content of the node
 */
class Node {
    id;
    content;
    constructor(id, content) {
        this.id = id;
        this.content = content;
    }
}
/**
 * LinkedNode class
 * prev is the previous node of the list
 * next is the next node of the list
 */
class LinkedNode extends Node {
    prev;
    next;
    constructor(node, prev, next) {
        super(node.id, node.content);
        this.prev = prev;
        this.next = next;
    }
}
/**
 * LinkedList class
 * head is the first node of the list
 * tail is the last node of the list
 * nodeMap is a map of the nodes of the list
 * we use a nodeMap to access the nodes of the list in O(1) time
 */
class LinkedList {
    head;
    tail;
    nodeMap;
    constructor() {
        this.head = null;
        this.tail = null;
        this.nodeMap = {};
    }
    /**
     * create a linked list from a list of elements
     * @param list list of elements
     * @param idField field used to identify the id of elements int the list
     * @returns linked list
     */
    static fromList(list, idField) {
        const linkedList = new LinkedList();
        for (const item of list) {
            if (typeof item !== "object" || item === null || !(idField in item))
                throw new Error(`Invalid item in list: ${JSON.stringify(item)}`);
            const id = item[idField];
            if (typeof id !== "string" && typeof id !== "number")
                throw new Error(`Invalid id field type: ${String(id)}`);
            linkedList.appendNode(new Node(id, item));
        }
        return linkedList;
    }
    /**
     * change the head of the linked list
     * @param newNode new head of the linked list
     */
    changeHead(newNode) {
        if (this.head)
            this.nodeMap[this.head.id].prev = newNode;
        this.head = newNode;
    }
    /**
     * change the tail of the linked list
     * @param newNode new tail of the linked list
     */
    changeTail(newNode) {
        if (this.tail)
            this.nodeMap[this.tail.id].next = newNode;
        this.tail = newNode;
    }
    /**
     * prepend a node to the linked list
     * @param node node to prepend
     */
    prependNode(node) {
        const newNode = new LinkedNode(node, null, this.head);
        if (!this.head || !this.tail) {
            // empty linked list
            this.head = this.tail = newNode;
        }
        else {
            newNode.next = this.head;
            this.nodeMap[this.head.id].prev = newNode;
            this.changeHead(newNode);
        }
        this.nodeMap[node.id] = newNode;
    }
    /**
     * append a node to the linked list
     * @param node node to append
     */
    appendNode(node) {
        const newNode = new LinkedNode(node, null, null);
        if (!this.head || !this.tail) {
            // empty linked list
            this.head = this.tail = newNode;
        }
        else {
            newNode.prev = this.tail;
            this.nodeMap[this.tail.id].next = newNode;
            this.changeTail(newNode);
        }
        this.nodeMap[node.id] = newNode;
    }
    /**
     * insert a node between two nodes
     * handle the case where the node is inserted before the head or after the tail
     * handle the case where the node is inserted between a deleted node and an existing node
     * raise an error if the node is inserted between two deleted nodes
     * @param prevNodeId id of the previous node
     * @param nextNodeId id of the next node
     * @param newNode node to insert
     */
    insertBetween(prevNodeId, nextNodeId, newNode) {
        if (prevNodeId && nextNodeId && prevNodeId === nextNodeId)
            throw new Error("prevNodeId and nextNodeId cannot be the same");
        if (prevNodeId === null) {
            this.prependNode(newNode);
        }
        else if (nextNodeId === null) {
            this.appendNode(newNode);
        }
        else {
            let prevNode = this.nodeMap[prevNodeId];
            let nextNode = this.nodeMap[nextNodeId];
            if (!prevNode && !nextNode)
                throw new Error("prevNode and nextNode do not exist, cannot add an orphan node");
            if (!prevNode) {
                // insert node before nextNode
                const nodeBeforeNextNode = nextNode.prev;
                if (!nodeBeforeNextNode) {
                    // this is the beginning of the list
                }
                else {
                    prevNode = nodeBeforeNextNode;
                }
            }
            if (!nextNode) {
                // insert node after prevNode
                const nodeAfterPrevNode = prevNode.next;
                if (!nodeAfterPrevNode) {
                    // this is the end of the list
                }
                else {
                    nextNode = nodeAfterPrevNode;
                }
            }
            const newLinkedNode = new LinkedNode(newNode, prevNode, nextNode);
            if (!prevNode)
                this.changeHead(newLinkedNode);
            else
                this.nodeMap[prevNode.id].next = newLinkedNode;
            if (!nextNode)
                this.changeTail(newLinkedNode);
            else
                this.nodeMap[nextNode.id].prev = newLinkedNode;
            this.nodeMap[newNode.id] = newLinkedNode;
        }
        this.handleCycle();
        this.handleOrphans();
    }
    /**
     * print the linked list (for debug purpose and test assertions)
     * @param reverse if true, print the linked list in reverse order
     * @returns string representation of the linked list
     */
    printFields(reverse = false) {
        let current = reverse ? this.tail : this.head;
        const fields = [];
        while (current) {
            fields.push(current.id.toString());
            current = reverse ? current.prev : current.next;
        }
        return fields.join(" -> ");
    }
    /**
     * print the linked list with the nodes and the prev and next fields (for debug purpose and test assertions)
     * @returns string representation of the linked list with the nodes and the prev and next fields
     */
    printFieldsWithNodesAndPrevNext() {
        const nodes = this.getNodesAsArray();
        return nodes.map(node => `(${node.prev?.id || "null"} <- ${node.id} -> ${node.next?.id || "null"})`).join(" \n ");
    }
    /**
     * get the nodes of the linked list as an array
     * @returns array of nodes
     */
    getNodesAsArray() {
        let current = this.head;
        const nodes = [];
        while (current) {
            nodes.push({ id: current.id, content: current.content, next: current.next, prev: current.prev });
            current = current.next;
        }
        return nodes;
    }
    /**
     * detect a cycle in the linked list
     * use Floyd's cycle finding algorithm (or Hare-Tortoise algorithm)
     * @returns node where the cycle starts or null if there is no cycle
     */
    detectCycle() {
        let slow = this.head;
        let fast = this.head;
        while (fast !== null && fast.next !== null) {
            slow = slow?.next ?? null;
            fast = fast?.next.next ?? null;
            // if slow and fast are the same, there is a cycle
            if (slow === fast)
                return slow;
        }
        // if fast or fast.next reaches the end, there is no cycle
        return null;
    }
    /**
     * detect the orphans in the linked list
     * @returns array of orphans
     */
    detectOrphans() {
        const visitedNodes = new Set();
        let currentNode = this.head;
        while (currentNode) {
            visitedNodes.add(currentNode.id);
            currentNode = currentNode.next;
        }
        const orphans = [];
        for (const nodeId in this.nodeMap) {
            if (!visitedNodes.has(this.nodeMap[nodeId].id))
                orphans.push(this.nodeMap[nodeId]);
        }
        return orphans;
    }
    /**
     * remove a cycle from the linked list
     * @param baseMeetPoint node where the cycle starts
     */
    removeCycle(baseMeetPoint) {
        let meetPoint = baseMeetPoint;
        if (meetPoint === null)
            return; // No cycle, no need to remove
        let slow = this.head;
        // if slow and meetPoint are the same, the cycle starts at the first node
        if (slow === meetPoint) {
            // Move until the node before the cycle is found
            while (meetPoint?.next !== slow)
                meetPoint = meetPoint?.next ?? null;
            meetPoint.next = null; // Remove the cycle
            return;
        }
        // Move slow and meetPoint one step at a time until they meet at the beginning of the cycle
        while (slow?.next !== meetPoint?.next) {
            slow = slow?.next ?? null;
            meetPoint = meetPoint?.next ?? null;
        }
        // Remove the cycle
        if (meetPoint)
            meetPoint.next = null;
    }
    /**
     * handle the cycle in the linked list
     * if a cycle is detected, remove it
     */
    handleCycle() {
        const meetPoint = this.detectCycle();
        if (meetPoint)
            this.removeCycle(meetPoint);
    }
    /**
     * handle the orphans in the linked list
     * if an orphan is detected, append it to the end of the list
     */
    handleOrphans() {
        const orphans = this.detectOrphans();
        if (orphans.length > 0)
            orphans.forEach(orphan => this.appendNode(orphan));
    }
    /**
     * filter the linked list without checking neighbors
     * @param linkedList linked list to filter
     * @param predicate predicate to filter the linked list
     * @returns filtered linked list
     */
    static filter(linkedList, predicate) {
        const nodes = Object.keys(linkedList.nodeMap).map(key => linkedList.nodeMap[key]).map(node => ({
            ...node,
            prev: node.prev?.id || null,
            next: node.next?.id || null,
        }));
        return nodes.filter(predicate);
    }
}
export { LinkedList, Node, LinkedNode };
