import { CollectionService } from "./CollectionService.js";
/**
 * Service class for managing automations.
 */
export class AutomationService extends CollectionService {
    constructor(dbHelper, clientId) {
        super(dbHelper, "settings_data_connections", clientId);
    }
    /**
     * List all automations, optionally filtering by trigger type and model ID.
     * @param options Options to filter the list of automations (optional)
     * @returns List of automations
     */
    async list(options = {}) {
        let automations = await super.list();
        // Apply filtering based on triggerType and primaryModelId
        automations = automations.filter((automation) => {
            return ((!options.triggerType || automation.trigger?.type === options.triggerType)
                && (!options.formId || automation.trigger?.form_ids?.includes(options.formId))
                && (!options.primaryModelId || automation.trigger?.model_id === options.primaryModelId));
        });
        return automations;
    }
}
export default AutomationService;
