import { CollectionService } from "./CollectionService.js";
/**
 * View Service
 */
export class ViewService extends CollectionService {
    constructor(dbHelper, clientId) {
        super(dbHelper, "views", clientId);
    }
    /**
     *
     * @param viewId Id of the view to update
     * @param fieldId Id of the field to update
     * @param input Object containing the field data
     * @returns Updated field
     */
    async updateField(viewId, fieldName, fieldValue) {
        return super.update(viewId, {
            [fieldName]: fieldValue,
        });
    }
}
export default ViewService;
