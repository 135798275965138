automation:
  add_step: Agregar paso
  add_trigger: Agregar un disparador
  create_new: Nueva automatización
  error_launch: El lanzamiento de la automatización ha fallado
  select_trigger_description: Seleccione un disparador para comenzar a configurar su automatización
  success_launch: El lanzamiento de la automatización se completó con éxito
  trigger_api: Cuando se recibe una llamada API
  trigger_graphquery: Cuando se ejecuta una consulta de graphQL
  trigger_transformation: Cuando se edita un objeto
automations:
  automation_name_placeholder: Nombre de automatización
  create_automation: Crear una nueva automatización
  creation_automation_name: Nombre de automatización
  modal_new_automation_description: >-
    Las automatizaciones le permiten crear procesos automatizados para crear,
    editar y guardar datos en Bonx.
  run:
    datetime: Fecha de ejecución
    id: Id du correr
  step:
    end_datetime: Fecha de finalización del paso
    name: Nombre del paso
    start_datetime: Fecha de inicio del paso
  tab_detail: automatización
  tab_history: Historia
blocks:
  qrCodeFullSize: Botón Código QR en modo de pantalla completa
button:
  about: Sobre
  back: atrás
  toggle_dark: modo oscuro
  toggle_langs: Alternar idioma
dashboard:
  last_30_days: últimos 30 días
data_connection:
  add_step: Agregar paso
  auth: método de autenticación
  auth_basic: básico
  data_connection_name_placeholder: mi conexion de datos
  data_connection_step_type_placeholder: Marcador de posición de tipo de paso de conexión de datos
  data_connection_steps: detalle de paso
  document_url: URL compartida de la hoja de Google
  input_data: variable de entrada
  json_edition_label: Editar conexión_datos json
  name: Nombre del paso
  password: Clave
  query: Consulta
  save_file: ¿Se guarda el archivo?
  save_output: Variable de salida
  step_json_edition_label: Edite el paso json
  tab: Pestaña de hoja de google seleccionada
  url: URL de API
  username: Nombre de usuario
email:
  body: Cuerpo del correo electronico
  subject: Asunto de correo electrónico
filters:
  operators_doesnt_include: no contiene
  operators_equals_to: (==) igual a
  operators_greater_than: (>) mayor que
  operators_greater_than_or_equal_to: (>=) mayor o igual que
  operators_includes: Contiene
  operators_is_empty: esta vacio
  operators_is_not_empty: no está vacío
  operators_less_than: (<) menos que
  operators_less_than_or_equal_to: (<=) menor que o igual a
  operators_no_operator: Sin condicion
  operators_not_equal_to: (!=) no es igual a
form.dollar_helper: >-
  Las variables precedidas por un signo "$" son internas al formulario. 

  Estas variables luego deben vincularse a los atributos del modelo a
  continuación.
form_field_max_value: valor máximo
forms:
  autofill_end: >-
    Bonx completa este campo automáticamente cuando se envía el formulario,
    utilizando la fecha y hora actuales.
  autofill_information: Este campo es rellenado automáticamente por Bonx
  autofill_start: >-
    Bonx completa este campo automáticamente cuando se abre el formulario,
    utilizando la fecha y hora actuales.
  base_model: modelo base
  create_form: Crear formulario
  create_new: Nueva forma
  creation_form_name: Nombre del formulario
  edit_no_field_warning: Este formulario actualmente no tiene campo
  field_multiple_answers_allowed: Puede seleccionar múltiples opciones aquí
  field_no_options: No hay opciones disponibles todavía.
  form_field_autocomplete: Autocompletar
  form_field_date: Fecha
  form_field_display: Monitor
  form_field_display_readquery_placeholder: Agregar una consulta de lectura secundaria (opcional)
  form_field_document: Documento
  form_field_has_min_max_values: Tiene valores mínimos máximos
  form_field_image: Imagen
  form_field_label_label: Nombre del campo
  form_field_min_value: Valor mínimo
  form_field_multiple_choice: Múltiples opciones
  form_field_name_label: clave de atributo
  form_field_number: Número
  form_field_option: Opción
  form_field_option_required: >-
    Se requiere un campo de opción de forma predeterminada, seleccione el campo
    "Múltiples opciones" si el formulario puede devolver un valor vacío.
  form_field_options_label: Lista de opciones
  form_field_options_placeholder: Añadir una opción
  form_field_printable_form: ''
  form_field_read_query: Leer consulta
  form_field_required_label: Obligatorio
  form_field_text: Campo de texto
  form_field_text_description_placeholder: Agregue una descripción o un ayudante aquí (opcional)
  form_field_text_label_placeholder: Agregar un nombre de campo
  form_field_text_name_placeholder: Agregar un nombre de atributo
  form_field_text_value_placeholder: Agregar un valor predeterminado (opcional)
  form_field_textarea_label: Texto largo
  form_field_write_query: Escribir consulta
  form_fields: Campos de formularios
  form_readquery_placeholder: Agregar una consulta de lectura principal (opcional)
  form_title_placeholder: Nombre del formulario
  form_writequery_placeholder: Agregar una consulta de escritura
  formreadquery_placeholder: Agregar una consulta de lectura principal (opcional)
  modal_new_form_description: >-
    El creador de formularios le permite generar formularios para que los
    completen sus equipos y, por lo tanto, automatiza la retroalimentación de
    datos.
  modal_share_form_description: >-
    Puede compartir este formulario por correo electrónico con un enlace único y
    de acceso público.
  no_related_model_found: Ningún modelo encontrado
  share: Compartir
  share_form_emails_label: Correos electrónicos
forms.action_delete_form: Eliminar formulario
forms.action_dupplicate_form: Formulario duplicado
forms.choose_attribute: Elegir atributo
forms.creation_import_fields: Importar atributos de modelo
forms.creation_related_model: Modelo relacionado
forms.creation_related_process: Proceso relacionado
forms.creation_related_process_step: Paso de proceso relacionado
forms.deletion_confirmation_body: |-
  ¿Está seguro de que desea eliminar este formulario? 
  Solo un administrador de Bonx podrá restaurarlo en el futuro.
forms.deletion_confirmation_title: Eliminación de formulario
forms.error_save_not_admin: Solo un administrador puede guardar un formulario editado
forms.form_field_no_related_model_placeholder: Ningún modelo relacionado
forms.form_field_no_relationship_placeholder: Sin relación
forms.form_field_relation: Relación
forms.form_field_relationship_allow_existing_object_label: Permitir la vinculación de un objeto existente
forms.form_field_relationship_allow_new_object_label: Permitir la creación de un nuevo elemento vinculado
forms.form_field_relationship_related_model_label: Seleccione un modelo relacionado
forms.form_field_relationship_value_label: Seleccione una relación
forms.form_share: Formulario compartido públicamente
forms.labels: Etiquetas
forms.new_form_advanced: Avanzado
forms.new_form_classic: Clásico
forms.no_model: Este formulario no está vinculado a un modelo existente
forms.no_related_step: No vinculado a un paso del proceso
forms.toast_deletion_success: Este formulario ha sido eliminado con éxito.
forms.toast_duplication_success: |-
  El formulario se ha duplicado con éxito. 
  Estás siendo redirigido a este nuevo formulario.
global:
  actions: Comportamiento
  add: Agregar
  automations: Automatizaciones
  cancel: Cancelar
  click_button_reload: ¡Haga clic en el botón de abajo para recargar!
  client: Empresa
  client_name: Nombre del cliente
  clients: Clientes
  close: Cerrar
  collaboration: colaboración
  comment: comentario
  compare: Comparar
  create: Crear
  currency: Divisa
  dashboard: Panel
  data: Datos
  data_connections: Conexiones de datos
  data_saved: Tus datos han sido guardados
  days: Dias
  delete: Borrar
  description: Descripción
  disabled: Desactivado
  disabled_users: Usuarios desactivados
  display: Mostrar
  duplicate: duplicar
  duration: Duración
  edit: Editar
  email: Correo electrónico
  entity: Entidad
  error: Error
  error_json: JSON es incorrecto
  error_login_invalid_password: Contraseña incorrecta.
  error_login_invalid_user: Usuario no encontrado, verifique dos veces el nombre de usuario.
  error_login_unknown: >-
    Error de registro desconocido, comuníquese con el equipo de Bonx usando el
    botón redondo en la parte inferior derecha de esta pantalla.
  error_password_validation: >-
    Su contraseña debe tener al menos 8 caracteres y debe contener al menos una
    minúscula, una mayúscula y un número.
  failed: Fallido
  file_input:
    no_file: Aún no ha enviado ningún archivo
  filter: Filtrar
  first_name: Nombre de pila
  follow_up: Hacer un seguimiento
  forgot_password: Se te olvidó tu contraseña
  forms: Formularios
  from: Desde
  groups: Grupos
  home: Casa
  id: Id
  is_required: es requerido
  label: Etiqueta
  labels: |-
    etiqueta | 
    etiqueta | 
    etiquetas
  large: Largo
  last_name: Apellido
  launch: Lanzar
  login: Acceso
  logout: Cerrar sesión
  medium: Medio
  missing_write_request: No proporcionó una solicitud de escritura
  models: |-
    modelo | 
    modelo | 
    modelos
  month:
    april_short: abr
    august_short: ago
    december_short: dic
    february_short: feb
    january_short: ene
    july_short: jul
    june_short: jun
    march_short: mar
    may_short: puede
    november_short: nov
    october_short: oct
    september_short: sep
  name: Nombre
  new: Nuevo
  new_version: ¡Una nueva version esta disponible!
  not-found: Extraviado
  objects: |-
    Objeto | 
    Objeto | 
    Objetos
  order: pedido
  overview: Visión general
  parameters: Parámetros
  password: Clave
  phone: Teléfono
  photo: Foto
  photo_url: Foto de URL
  process: Proceso
  project: Proyecto
  projects: Proyectos
  reload: Recargar
  remember_me: Acuérdate de mí
  reset_email_sent: >-
    Se ha enviado un correo electrónico para restablecer su contraseña. Haz clic
    en el enlace cuando lo recibas.
  reset_password: Restablecer la contraseña
  role: Role
  rows: filas
  save: Guardar
  saving: Ahorro...
  search: Búsqueda
  send: Enviar
  send_reset_email: Enviar correo electrónico de restablecimiento
  settings: Ajustes
  show: Espectáculo
  small: Pequeña
  status: Estado
  success: Éxito
  table:
    nav: Paginación de tablas
  task: Tarea | Tarea | Tareas
  total_report: Informe total
  training_and_help: centro de recursos
  type: Tipo
  update: Actualizar
  user: Usuario
  users: Usuarios
  users_and_permissions: Usuarios y permisos
  validate: Validar
  value: Valor
  views: |-
    Ver | 
    Ver | 
    Puntos de vista
  week: Semana
  you_dont_have_access_to_this_page: No tienes acceso a esta página
global.attribute: Atributo
global.close: Cerca
global.instance: Instancia
global.model: Modelo
global.toast_key_copy_success_content: La clave se ha copiado con éxito en su portapapeles.
global.toast_succes_copy_to_clipboard: El contenido de la tabla se ha copiado con éxito en su portapapeles
global.unsinchronized_store_header_content: |-
  El siguiente elemento ha sido actualizado. 
  Por lo tanto, no podrá guardar ninguna edición antes de actualizar la página.
global.unsinchronized_store_header_content_pt1: Los siguientes elementos han sido actualizados
global.unsinchronized_store_header_content_pt2: >-
  Por lo tanto, no se le permitirá realizar ninguna edición antes de actualizar
  la página.
global.unsinchronized_store_header_title: La configuración de Bonx ha sido editada por otro usuario
global.update: Actualizar
groups:
  group_name: Nombre del grupo
  group_order: Orden de grupo
  groups_list: Lista de grupos
  toast_edit_success: Los cambios guardados en el grupo se han guardado correctamente
home:
  greetings: Saludos
modal_confirmation_popup_cancel: No.
modal_confirmation_popup_confirm: Sí
object:
  attribute:
    attributes: |-
      atributo | 
      atributo | 
      atributos
  attributes: Atributos de objeto
  attributes_list: Lista de atributos
  error_attribute_with_same_name_exists: Un atributo existente ya tiene el mismo nombre
  error_loading: Hubo un error al cargar el contenido de esta página
  error_no_primary_attribute: No se ha definido ningún atributo principal para este modelo.
  related_objects: |-
    Objeto relacionado | 
    Objeto relacionado | 
    Objetos relacionados
  relation:
    attributes_list: Lista de atributos de relación
  relationship:
    object: Objeto vinculado
    to_object: al objeto
  relationship_list: Lista de relaciones
  relationships: |-
    relación | 
    relación | 
    relaciones
  selected_setting_object: Tipo de objeto
objects:
  add_attribute: Agregar un atributo
  add_label: crear una etiqueta
  add_object: Crear un objeto
  attributes_subtype_float: Flotante
  attributes_subtype_integer: Entero
  attributes_subtype_link: Vínculo
  attributes_subtype_plain_text: Texto sin formato
  data_model: Modelo de datos
  data_models: Modelos de datos
  edit_attribute: Editar un atributo
  edit_label: Editar una etiqueta
  edit_object: Editar un objeto
  label:
    placeholder: Por favor elige una etiqueta
  label_objects: Objetos vinculados a esta etiqueta
  label_relations: Relaciones vinculadas a esta etiqueta
  labels: Etiquetas
  labels_list: Lista de etiquetas
  models: Modelos
  models_list: Listas de modelos
  objects_list: Lista de objetos
  overview: visión general
  relation:
    add_relation: Agregar una relación
    edit_relation: Editar una relación
  relations: Relaciones
otable:
  error:
    table_has_no_column: >-
      Esta tabla será visible tan pronto como se agregue una clave como columna,
      comuníquese con un administrador de su empresa.
pages:
  automation: Automatización
  data-connection: Conección de datos
  object: Objetos
process.finished: Finalizado
process.in_progress: En curso
process.waiting: Espera
processes:
  next_step: Próximo paso
  step_name: Nombre del paso
profile:
  gantt_size_label: Visualización del gráfico de Gantt
  home_page: página de inicio
  my_profile: Mi perfil
  settings: Ajustes
timeline:
  external_user: Usuario externo
toast:
  form_submit_content: El formulario se ha enviado correctamente
  print_success: Una página se abrirá pronto
  view_error_no_model_title: Bonx pronto le permitirá editar vistas complejas
  view_error_no_model_title_content: >-
    Esta vista se ha creado manualmente, comuníquese con su departamento de TI o
    un administrador de Bonx.
toast.form_field_file_submit_content_error: Este formulario requiere que se envíe un archivo.
user:
  add_label: crear una etiqueta
  data_model:
    labels: Etiquetas
    models: Modelos
    relations: Relaciones
  data_models: Modelos de datos
  edit_label: Editar una etiqueta
  label:
    placeholder: Busca una etiqueta...
  models_list: Lista de etiquetas
view:
  action_move_up: Ascender
  no_title: Sin título
  toast_edit_success: El detalle de la vista se ha editado con éxito.
views:
  action_move_down: Mover hacia abajo
  add_attribute: Nuevo atributo
  add_block: Nuevo bloque
  block_name: Nombre del bloque
  block_type: Tipo de bloque
  create_new_view: Crear una nueva vista
  create_new_view_description: >-
    Las vistas le permiten visualizar los datos que le interesan, en diferentes
    formas y compartirlos con sus equipos.
  create_view: Crear una nueva vista
  creation_automation_name_label: Nombre de automatización
  creation_form_name_label: Nombre del formulario
  creation_view_model: Modelo relacionado
  creation_view_name: Nombre de la vista
  default_setup_element_name: Datos relacionados con el modelo
  default_view_group_name: Grupo predeterminado
  edit_attributes_options: Opciones de atributos
  edit_attributes_options_add_colors: Colores
  edit_attributes_options_add_icon: Iconos
  edit_attributes_options_add_status: Estado
  edit_attributes_options_background_color: Color de fondo
  edit_attributes_options_border_color: Color del borde
  edit_attributes_options_description: >-
    Seleccionar las opciones de un atributo le permite cambiar su configuración
    profunda.
  edit_attributes_options_hide_json: Ocultar detalles
  edit_attributes_options_icon: Icono
  edit_attributes_options_no_attributes: Este bloque no posee ningún atributo todavía.
  edit_attributes_options_show_json: Mostrar detalles
  edit_attributes_options_status_error: 'Estado: error'
  edit_attributes_options_status_success: 'Estado: éxito'
  edit_attributes_options_status_warning: 'Estado: advertencia'
  edit_import_model: Importar un modelo
  edit_import_model_description: >-
    Importar un modelo le permite agregar en un clic todos sus atributos como
    ejes del bloque.
  edition_deletion: Eliminar la vista
  edition_no_columns: >-
    Estas casillas de verificación estarán visibles tan pronto como se agregue
    un atributo al modelo, comuníquese con un administrador de su empresa.
  edition_no_related_objects: Este modelo no está relacionado con ningún otro modelo.
  edition_primary_table: tabla de datos primarios
  edition_secondary_related_objects: Objetos relacionados
  edition_selected_columns: columnas
  error:
    query_invalid_or_missing: La solicitud proporcionada está vacía o resultó en un error.
  filter_label_placeholder: Etiqueta para filtrar
  filter_value_placeholder: Valor
  filter_view: Filtrar esta vista
  filters_add_filter: Agregar una condición
  gantt:
    bar:
      no_label: Este bar no posee ninguna etiqueta.
    precision:
      day: días
      display_by: Mostrar por
      month: meses
  id: Id
  label: Etiqueta
  modal_new_view_description: >-
    Las vistas le permiten visualizar sus datos de la forma que desee y
    compartirlos con sus equipos.
  query: Solicitud de GraphQL
  toast_edit_success: La vista se ha editado con éxito.
  type: Escribe
  view_block_name_placeholder: Mi nombre de bloque de vista (opcional)
  view_name_placeholder: Ver nombre
  view_parameters_title: Ver parámetros
  views_list: Lista de vistas
views.deletion_confirmation_body: >-
  ¿Está seguro de que desea eliminar esta vista? 

  Todos sus datos se guardarán en caso de que cometas un error y quieras
  revertirlo.
views.deletion_confirmation_title: Ver eliminación
views.toast_deletion_success: Esta vista se ha eliminado correctamente.
