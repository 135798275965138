/* eslint-disable eslint-comments/no-unlimited-disable */
/* eslint-disable */
// @ts-nocheck
// Generated by Peggy 3.0.2.
//
// https://peggyjs.org/
function peg$subclass(child, parent) {
    function C() { this.constructor = child; }
    C.prototype = parent.prototype;
    child.prototype = new C();
}
function peg$SyntaxError(message, expected, found, location) {
    var self = Error.call(this, message);
    // istanbul ignore next Check is a necessary evil to support older environments
    if (Object.setPrototypeOf) {
        Object.setPrototypeOf(self, peg$SyntaxError.prototype);
    }
    self.expected = expected;
    self.found = found;
    self.location = location;
    self.name = "SyntaxError";
    return self;
}
peg$subclass(peg$SyntaxError, Error);
function peg$padEnd(str, targetLength, padString) {
    padString = padString || " ";
    if (str.length > targetLength) {
        return str;
    }
    targetLength -= str.length;
    padString += padString.repeat(targetLength);
    return str + padString.slice(0, targetLength);
}
peg$SyntaxError.prototype.format = function (sources) {
    var str = "Error: " + this.message;
    if (this.location) {
        var src = null;
        var k;
        for (k = 0; k < sources.length; k++) {
            if (sources[k].source === this.location.source) {
                src = sources[k].text.split(/\r\n|\n|\r/g);
                break;
            }
        }
        var s = this.location.start;
        var offset_s = (this.location.source && (typeof this.location.source.offset === "function"))
            ? this.location.source.offset(s)
            : s;
        var loc = this.location.source + ":" + offset_s.line + ":" + offset_s.column;
        if (src) {
            var e = this.location.end;
            var filler = peg$padEnd("", offset_s.line.toString().length, ' ');
            var line = src[s.line - 1];
            var last = s.line === e.line ? e.column : line.length + 1;
            var hatLen = (last - s.column) || 1;
            str += "\n --> " + loc + "\n"
                + filler + " |\n"
                + offset_s.line + " | " + line + "\n"
                + filler + " | " + peg$padEnd("", s.column - 1, ' ')
                + peg$padEnd("", hatLen, "^");
        }
        else {
            str += "\n at " + loc;
        }
    }
    return str;
};
peg$SyntaxError.buildMessage = function (expected, found) {
    var DESCRIBE_EXPECTATION_FNS = {
        literal: function (expectation) {
            return "\"" + literalEscape(expectation.text) + "\"";
        },
        class: function (expectation) {
            var escapedParts = expectation.parts.map(function (part) {
                return Array.isArray(part)
                    ? classEscape(part[0]) + "-" + classEscape(part[1])
                    : classEscape(part);
            });
            return "[" + (expectation.inverted ? "^" : "") + escapedParts.join("") + "]";
        },
        any: function () {
            return "any character";
        },
        end: function () {
            return "end of input";
        },
        other: function (expectation) {
            return expectation.description;
        }
    };
    function hex(ch) {
        return ch.charCodeAt(0).toString(16).toUpperCase();
    }
    function literalEscape(s) {
        return s
            .replace(/\\/g, "\\\\")
            .replace(/"/g, "\\\"")
            .replace(/\0/g, "\\0")
            .replace(/\t/g, "\\t")
            .replace(/\n/g, "\\n")
            .replace(/\r/g, "\\r")
            .replace(/[\x00-\x0F]/g, function (ch) { return "\\x0" + hex(ch); })
            .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) { return "\\x" + hex(ch); });
    }
    function classEscape(s) {
        return s
            .replace(/\\/g, "\\\\")
            .replace(/\]/g, "\\]")
            .replace(/\^/g, "\\^")
            .replace(/-/g, "\\-")
            .replace(/\0/g, "\\0")
            .replace(/\t/g, "\\t")
            .replace(/\n/g, "\\n")
            .replace(/\r/g, "\\r")
            .replace(/[\x00-\x0F]/g, function (ch) { return "\\x0" + hex(ch); })
            .replace(/[\x10-\x1F\x7F-\x9F]/g, function (ch) { return "\\x" + hex(ch); });
    }
    function describeExpectation(expectation) {
        return DESCRIBE_EXPECTATION_FNS[expectation.type](expectation);
    }
    function describeExpected(expected) {
        var descriptions = expected.map(describeExpectation);
        var i, j;
        descriptions.sort();
        if (descriptions.length > 0) {
            for (i = 1, j = 1; i < descriptions.length; i++) {
                if (descriptions[i - 1] !== descriptions[i]) {
                    descriptions[j] = descriptions[i];
                    j++;
                }
            }
            descriptions.length = j;
        }
        switch (descriptions.length) {
            case 1:
                return descriptions[0];
            case 2:
                return descriptions[0] + " or " + descriptions[1];
            default:
                return descriptions.slice(0, -1).join(", ")
                    + ", or "
                    + descriptions[descriptions.length - 1];
        }
    }
    function describeFound(found) {
        return found ? "\"" + literalEscape(found) + "\"" : "end of input";
    }
    return "Expected " + describeExpected(expected) + " but " + describeFound(found) + " found.";
};
function peg$parse(input, options) {
    options = options !== undefined ? options : {};
    var peg$FAILED = {};
    var peg$source = options.grammarSource;
    var peg$startRuleFunctions = { start: peg$parsestart };
    var peg$startRuleFunction = peg$parsestart;
    var peg$c0 = "(";
    var peg$c1 = ")";
    var peg$c2 = "*";
    var peg$c3 = "x";
    var peg$c4 = "-";
    var peg$c5 = "+";
    var peg$c6 = "%";
    var peg$c7 = "/";
    var peg$c8 = "^";
    var peg$c9 = "NULL";
    var peg$c10 = "null";
    var peg$c11 = "__relation_";
    var peg$c12 = "_";
    var peg$c13 = "$.";
    var peg$c14 = "$";
    var peg$c15 = "true";
    var peg$c16 = "false";
    var peg$c17 = ".";
    var peg$c18 = "'";
    var peg$c19 = ",";
    var peg$c20 = " ";
    var peg$r0 = /^[a-zA-Z]/;
    var peg$r1 = /^[a-zA-Z0-9._@]/;
    var peg$r2 = /^[a-zA-Z0-9._]/;
    var peg$r3 = /^[0-9]/;
    var peg$r4 = /^[^']/;
    var peg$e0 = peg$classExpectation([["a", "z"], ["A", "Z"]], false, false);
    var peg$e1 = peg$literalExpectation("(", false);
    var peg$e2 = peg$literalExpectation(")", false);
    var peg$e3 = peg$literalExpectation("*", false);
    var peg$e4 = peg$literalExpectation("x", false);
    var peg$e5 = peg$literalExpectation("-", false);
    var peg$e6 = peg$literalExpectation("+", false);
    var peg$e7 = peg$literalExpectation("%", false);
    var peg$e8 = peg$literalExpectation("/", false);
    var peg$e9 = peg$literalExpectation("^", false);
    var peg$e10 = peg$literalExpectation("NULL", false);
    var peg$e11 = peg$literalExpectation("null", false);
    var peg$e12 = peg$literalExpectation("__relation_", false);
    var peg$e13 = peg$literalExpectation("_", false);
    var peg$e14 = peg$literalExpectation("$.", false);
    var peg$e15 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], ".", "_", "@"], false, false);
    var peg$e16 = peg$literalExpectation("$", false);
    var peg$e17 = peg$classExpectation([["a", "z"], ["A", "Z"], ["0", "9"], ".", "_"], false, false);
    var peg$e18 = peg$literalExpectation("true", false);
    var peg$e19 = peg$literalExpectation("false", false);
    var peg$e20 = peg$literalExpectation(".", false);
    var peg$e21 = peg$classExpectation([["0", "9"]], false, false);
    var peg$e22 = peg$literalExpectation("'", false);
    var peg$e23 = peg$classExpectation(["'"], true, false);
    var peg$e24 = peg$literalExpectation(",", false);
    var peg$e25 = peg$otherExpectation("whitespace");
    var peg$e26 = peg$literalExpectation(" ", false);
    var peg$f0 = function (left, right) { return { type: 'params', exprs: [left].concat(right.exprs) }; };
    var peg$f1 = function (left, right) { return { type: 'params', exprs: [left, right] }; };
    var peg$f2 = function (name, exprs) { return { type: 'function', name: name.join(''), exprs }; };
    var peg$f3 = function (exprs) { return { type: 'group', exprs }; };
    var peg$f4 = function (left, operator, right) { return { type: 'operation', operator, left, right }; };
    var peg$f5 = function (left, operator, right) { return { type: 'operation', operator, left, right }; };
    var peg$f6 = function () { return { type: 'null' }; };
    var peg$f7 = function (name) { return { type: 'variable', name: name.length === 2 ? (name[0].join('') + (name[1] ? name[1].join('') : '') + (name[2] ? name[2].join('') : '')) : name[0] + (name[1] ? name[1].join('') : '') }; };
    var peg$f8 = function (name) { return { type: 'param', name: name[1].join('') }; };
    var peg$f9 = function (value) { return { type: 'literal', value }; };
    var peg$f10 = function (value) { return { type: 'literal', value }; };
    var peg$f11 = function () { return Number(text()); };
    var peg$f12 = function () { return Number(text()); };
    var peg$f13 = function (chars) { return chars.join(''); };
    var peg$currPos = 0;
    var peg$savedPos = 0;
    var peg$posDetailsCache = [{ line: 1, column: 1 }];
    var peg$maxFailPos = 0;
    var peg$maxFailExpected = [];
    var peg$silentFails = 0;
    var peg$result;
    if ("startRule" in options) {
        if (!(options.startRule in peg$startRuleFunctions)) {
            throw new Error("Can't start parsing from rule \"" + options.startRule + "\".");
        }
        peg$startRuleFunction = peg$startRuleFunctions[options.startRule];
    }
    function text() {
        return input.substring(peg$savedPos, peg$currPos);
    }
    function offset() {
        return peg$savedPos;
    }
    function range() {
        return {
            source: peg$source,
            start: peg$savedPos,
            end: peg$currPos
        };
    }
    function location() {
        return peg$computeLocation(peg$savedPos, peg$currPos);
    }
    function expected(description, location) {
        location = location !== undefined
            ? location
            : peg$computeLocation(peg$savedPos, peg$currPos);
        throw peg$buildStructuredError([peg$otherExpectation(description)], input.substring(peg$savedPos, peg$currPos), location);
    }
    function error(message, location) {
        location = location !== undefined
            ? location
            : peg$computeLocation(peg$savedPos, peg$currPos);
        throw peg$buildSimpleError(message, location);
    }
    function peg$literalExpectation(text, ignoreCase) {
        return { type: "literal", text: text, ignoreCase: ignoreCase };
    }
    function peg$classExpectation(parts, inverted, ignoreCase) {
        return { type: "class", parts: parts, inverted: inverted, ignoreCase: ignoreCase };
    }
    function peg$anyExpectation() {
        return { type: "any" };
    }
    function peg$endExpectation() {
        return { type: "end" };
    }
    function peg$otherExpectation(description) {
        return { type: "other", description: description };
    }
    function peg$computePosDetails(pos) {
        var details = peg$posDetailsCache[pos];
        var p;
        if (details) {
            return details;
        }
        else {
            p = pos - 1;
            while (!peg$posDetailsCache[p]) {
                p--;
            }
            details = peg$posDetailsCache[p];
            details = {
                line: details.line,
                column: details.column
            };
            while (p < pos) {
                if (input.charCodeAt(p) === 10) {
                    details.line++;
                    details.column = 1;
                }
                else {
                    details.column++;
                }
                p++;
            }
            peg$posDetailsCache[pos] = details;
            return details;
        }
    }
    function peg$computeLocation(startPos, endPos, offset) {
        var startPosDetails = peg$computePosDetails(startPos);
        var endPosDetails = peg$computePosDetails(endPos);
        var res = {
            source: peg$source,
            start: {
                offset: startPos,
                line: startPosDetails.line,
                column: startPosDetails.column
            },
            end: {
                offset: endPos,
                line: endPosDetails.line,
                column: endPosDetails.column
            }
        };
        if (offset && peg$source && (typeof peg$source.offset === "function")) {
            res.start = peg$source.offset(res.start);
            res.end = peg$source.offset(res.end);
        }
        return res;
    }
    function peg$fail(expected) {
        if (peg$currPos < peg$maxFailPos) {
            return;
        }
        if (peg$currPos > peg$maxFailPos) {
            peg$maxFailPos = peg$currPos;
            peg$maxFailExpected = [];
        }
        peg$maxFailExpected.push(expected);
    }
    function peg$buildSimpleError(message, location) {
        return new peg$SyntaxError(message, null, null, location);
    }
    function peg$buildStructuredError(expected, found, location) {
        return new peg$SyntaxError(peg$SyntaxError.buildMessage(expected, found), expected, found, location);
    }
    function peg$parsestart() {
        var s0;
        s0 = peg$parseExpression();
        return s0;
    }
    function peg$parseParam() {
        var s0;
        s0 = peg$parseGroup();
        if (s0 === peg$FAILED) {
            s0 = peg$parseFunction();
            if (s0 === peg$FAILED) {
                s0 = peg$parseBoolean();
                if (s0 === peg$FAILED) {
                    s0 = peg$parseNull();
                    if (s0 === peg$FAILED) {
                        s0 = peg$parseVariable();
                        if (s0 === peg$FAILED) {
                            s0 = peg$parseNamedParam();
                            if (s0 === peg$FAILED) {
                                s0 = peg$parseLiteral();
                            }
                        }
                    }
                }
            }
        }
        return s0;
    }
    function peg$parseExpression() {
        var s0;
        s0 = peg$parseOperation();
        if (s0 === peg$FAILED) {
            s0 = peg$parseParam();
        }
        return s0;
    }
    function peg$parseParams() {
        var s0, s1, s2, s3, s4, s5;
        s0 = peg$currPos;
        s1 = peg$parseExpression();
        if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseParamSeparator();
            if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                s5 = peg$parseParams();
                if (s5 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f0(s1, s5);
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        }
        else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseExpression();
            if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                s3 = peg$parseParamSeparator();
                if (s3 !== peg$FAILED) {
                    s4 = peg$parse_();
                    s5 = peg$parseExpression();
                    if (s5 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f1(s1, s5);
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        }
        return s0;
    }
    function peg$parseFunctionExpression() {
        var s0;
        s0 = peg$parseParams();
        if (s0 === peg$FAILED) {
            s0 = peg$parseExpression();
        }
        return s0;
    }
    function peg$parseFunction() {
        var s0, s1, s2, s3, s4;
        s0 = peg$currPos;
        s1 = [];
        if (peg$r0.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
        }
        else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e0);
            }
        }
        if (s2 !== peg$FAILED) {
            while (s2 !== peg$FAILED) {
                s1.push(s2);
                if (peg$r0.test(input.charAt(peg$currPos))) {
                    s2 = input.charAt(peg$currPos);
                    peg$currPos++;
                }
                else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e0);
                    }
                }
            }
        }
        else {
            s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 40) {
                s2 = peg$c0;
                peg$currPos++;
            }
            else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e1);
                }
            }
            if (s2 !== peg$FAILED) {
                s3 = [];
                s4 = peg$parseFunctionExpression();
                while (s4 !== peg$FAILED) {
                    s3.push(s4);
                    s4 = peg$parseFunctionExpression();
                }
                if (input.charCodeAt(peg$currPos) === 41) {
                    s4 = peg$c1;
                    peg$currPos++;
                }
                else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e2);
                    }
                }
                if (s4 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f2(s1, s3);
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        }
        else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        return s0;
    }
    function peg$parseGroup() {
        var s0, s1, s2, s3, s4, s5;
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 40) {
            s1 = peg$c0;
            peg$currPos++;
        }
        else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e1);
            }
        }
        if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = [];
            s4 = peg$parseExpression();
            while (s4 !== peg$FAILED) {
                s3.push(s4);
                s4 = peg$parseExpression();
            }
            s4 = peg$parse_();
            if (input.charCodeAt(peg$currPos) === 41) {
                s5 = peg$c1;
                peg$currPos++;
            }
            else {
                s5 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e2);
                }
            }
            if (s5 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f3(s3);
            }
            else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        }
        else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        return s0;
    }
    function peg$parseOperation() {
        var s0, s1, s2, s3, s4, s5;
        s0 = peg$currPos;
        s1 = peg$parseParam();
        if (s1 !== peg$FAILED) {
            s2 = peg$parse_();
            s3 = peg$parseOperator();
            if (s3 !== peg$FAILED) {
                s4 = peg$parse_();
                s5 = peg$parseOperation();
                if (s5 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f4(s1, s3, s5);
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        }
        else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        if (s0 === peg$FAILED) {
            s0 = peg$currPos;
            s1 = peg$parseParam();
            if (s1 !== peg$FAILED) {
                s2 = peg$parse_();
                s3 = peg$parseOperator();
                if (s3 !== peg$FAILED) {
                    s4 = peg$parse_();
                    s5 = peg$parseParam();
                    if (s5 !== peg$FAILED) {
                        peg$savedPos = s0;
                        s0 = peg$f5(s1, s3, s5);
                    }
                    else {
                        peg$currPos = s0;
                        s0 = peg$FAILED;
                    }
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        }
        return s0;
    }
    function peg$parseOperator() {
        var s0;
        if (input.charCodeAt(peg$currPos) === 42) {
            s0 = peg$c2;
            peg$currPos++;
        }
        else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e3);
            }
        }
        if (s0 === peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 120) {
                s0 = peg$c3;
                peg$currPos++;
            }
            else {
                s0 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e4);
                }
            }
            if (s0 === peg$FAILED) {
                if (input.charCodeAt(peg$currPos) === 45) {
                    s0 = peg$c4;
                    peg$currPos++;
                }
                else {
                    s0 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e5);
                    }
                }
                if (s0 === peg$FAILED) {
                    if (input.charCodeAt(peg$currPos) === 43) {
                        s0 = peg$c5;
                        peg$currPos++;
                    }
                    else {
                        s0 = peg$FAILED;
                        if (peg$silentFails === 0) {
                            peg$fail(peg$e6);
                        }
                    }
                    if (s0 === peg$FAILED) {
                        if (input.charCodeAt(peg$currPos) === 37) {
                            s0 = peg$c6;
                            peg$currPos++;
                        }
                        else {
                            s0 = peg$FAILED;
                            if (peg$silentFails === 0) {
                                peg$fail(peg$e7);
                            }
                        }
                        if (s0 === peg$FAILED) {
                            if (input.charCodeAt(peg$currPos) === 47) {
                                s0 = peg$c7;
                                peg$currPos++;
                            }
                            else {
                                s0 = peg$FAILED;
                                if (peg$silentFails === 0) {
                                    peg$fail(peg$e8);
                                }
                            }
                            if (s0 === peg$FAILED) {
                                if (input.charCodeAt(peg$currPos) === 94) {
                                    s0 = peg$c8;
                                    peg$currPos++;
                                }
                                else {
                                    s0 = peg$FAILED;
                                    if (peg$silentFails === 0) {
                                        peg$fail(peg$e9);
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
        return s0;
    }
    function peg$parseNull() {
        var s0, s1;
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 4) === peg$c9) {
            s1 = peg$c9;
            peg$currPos += 4;
        }
        else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e10);
            }
        }
        if (s1 === peg$FAILED) {
            if (input.substr(peg$currPos, 4) === peg$c10) {
                s1 = peg$c10;
                peg$currPos += 4;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e11);
                }
            }
        }
        if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$f6();
        }
        s0 = s1;
        return s0;
    }
    function peg$parseVariable() {
        var s0, s1, s2, s3, s4, s5, s6, s7, s8;
        s0 = peg$currPos;
        s1 = peg$currPos;
        s2 = peg$currPos;
        s3 = [];
        if (input.substr(peg$currPos, 11) === peg$c11) {
            s4 = peg$c11;
            peg$currPos += 11;
        }
        else {
            s4 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e12);
            }
        }
        while (s4 !== peg$FAILED) {
            s3.push(s4);
            if (s3.length >= 1) {
                s4 = peg$FAILED;
            }
            else {
                if (input.substr(peg$currPos, 11) === peg$c11) {
                    s4 = peg$c11;
                    peg$currPos += 11;
                }
                else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e12);
                    }
                }
            }
        }
        s4 = [];
        if (input.charCodeAt(peg$currPos) === 95) {
            s5 = peg$c12;
            peg$currPos++;
        }
        else {
            s5 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e13);
            }
        }
        while (s5 !== peg$FAILED) {
            s4.push(s5);
            if (s4.length >= 1) {
                s5 = peg$FAILED;
            }
            else {
                if (input.charCodeAt(peg$currPos) === 95) {
                    s5 = peg$c12;
                    peg$currPos++;
                }
                else {
                    s5 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e13);
                    }
                }
            }
        }
        s5 = [];
        if (input.substr(peg$currPos, 2) === peg$c13) {
            s6 = peg$c13;
            peg$currPos += 2;
        }
        else {
            s6 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e14);
            }
        }
        while (s6 !== peg$FAILED) {
            s5.push(s6);
            if (s5.length >= 1) {
                s6 = peg$FAILED;
            }
            else {
                if (input.substr(peg$currPos, 2) === peg$c13) {
                    s6 = peg$c13;
                    peg$currPos += 2;
                }
                else {
                    s6 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e14);
                    }
                }
            }
        }
        s6 = peg$currPos;
        s7 = [];
        if (peg$r0.test(input.charAt(peg$currPos))) {
            s8 = input.charAt(peg$currPos);
            peg$currPos++;
        }
        else {
            s8 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e0);
            }
        }
        while (s8 !== peg$FAILED) {
            s7.push(s8);
            if (s7.length >= 1) {
                s8 = peg$FAILED;
            }
            else {
                if (peg$r0.test(input.charAt(peg$currPos))) {
                    s8 = input.charAt(peg$currPos);
                    peg$currPos++;
                }
                else {
                    s8 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e0);
                    }
                }
            }
        }
        if (s7.length < 1) {
            peg$currPos = s6;
            s6 = peg$FAILED;
        }
        else {
            s6 = s7;
        }
        if (s6 !== peg$FAILED) {
            s3 = [s3, s4, s5, s6];
            s2 = s3;
        }
        else {
            peg$currPos = s2;
            s2 = peg$FAILED;
        }
        if (s2 !== peg$FAILED) {
            s3 = [];
            if (peg$r1.test(input.charAt(peg$currPos))) {
                s4 = input.charAt(peg$currPos);
                peg$currPos++;
            }
            else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e15);
                }
            }
            while (s4 !== peg$FAILED) {
                s3.push(s4);
                if (peg$r1.test(input.charAt(peg$currPos))) {
                    s4 = input.charAt(peg$currPos);
                    peg$currPos++;
                }
                else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e15);
                    }
                }
            }
            s2 = [s2, s3];
            s1 = s2;
        }
        else {
            peg$currPos = s1;
            s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$f7(s1);
        }
        s0 = s1;
        return s0;
    }
    function peg$parseNamedParam() {
        var s0, s1, s2, s3, s4;
        s0 = peg$currPos;
        s1 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 36) {
            s2 = peg$c14;
            peg$currPos++;
        }
        else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e16);
            }
        }
        if (s2 !== peg$FAILED) {
            s3 = [];
            if (peg$r2.test(input.charAt(peg$currPos))) {
                s4 = input.charAt(peg$currPos);
                peg$currPos++;
            }
            else {
                s4 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e17);
                }
            }
            while (s4 !== peg$FAILED) {
                s3.push(s4);
                if (peg$r2.test(input.charAt(peg$currPos))) {
                    s4 = input.charAt(peg$currPos);
                    peg$currPos++;
                }
                else {
                    s4 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e17);
                    }
                }
            }
            s2 = [s2, s3];
            s1 = s2;
        }
        else {
            peg$currPos = s1;
            s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$f8(s1);
        }
        s0 = s1;
        return s0;
    }
    function peg$parseLiteral() {
        var s0, s1;
        s0 = peg$currPos;
        s1 = peg$parseNumber();
        if (s1 === peg$FAILED) {
            s1 = peg$parseBoolean();
            if (s1 === peg$FAILED) {
                s1 = peg$parseString();
            }
        }
        if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$f9(s1);
        }
        s0 = s1;
        return s0;
    }
    function peg$parseBoolean() {
        var s0, s1;
        s0 = peg$currPos;
        if (input.substr(peg$currPos, 4) === peg$c15) {
            s1 = peg$c15;
            peg$currPos += 4;
        }
        else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e18);
            }
        }
        if (s1 === peg$FAILED) {
            if (input.substr(peg$currPos, 5) === peg$c16) {
                s1 = peg$c16;
                peg$currPos += 5;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e19);
                }
            }
        }
        if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$f10(s1);
        }
        s0 = s1;
        return s0;
    }
    function peg$parseNumber() {
        var s0;
        s0 = peg$parseFloat();
        if (s0 === peg$FAILED) {
            s0 = peg$parseInteger();
        }
        return s0;
    }
    function peg$parseFloat() {
        var s0, s1, s2, s3, s4;
        s0 = peg$currPos;
        s1 = [];
        s2 = peg$parseInteger();
        if (s2 !== peg$FAILED) {
            while (s2 !== peg$FAILED) {
                s1.push(s2);
                s2 = peg$parseInteger();
            }
        }
        else {
            s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
            if (input.charCodeAt(peg$currPos) === 46) {
                s2 = peg$c17;
                peg$currPos++;
            }
            else {
                s2 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e20);
                }
            }
            if (s2 !== peg$FAILED) {
                s3 = [];
                s4 = peg$parseInteger();
                if (s4 !== peg$FAILED) {
                    while (s4 !== peg$FAILED) {
                        s3.push(s4);
                        s4 = peg$parseInteger();
                    }
                }
                else {
                    s3 = peg$FAILED;
                }
                if (s3 !== peg$FAILED) {
                    peg$savedPos = s0;
                    s0 = peg$f11();
                }
                else {
                    peg$currPos = s0;
                    s0 = peg$FAILED;
                }
            }
            else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        }
        else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        return s0;
    }
    function peg$parseInteger() {
        var s0, s1, s2;
        s0 = peg$currPos;
        s1 = [];
        if (peg$r3.test(input.charAt(peg$currPos))) {
            s2 = input.charAt(peg$currPos);
            peg$currPos++;
        }
        else {
            s2 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e21);
            }
        }
        if (s2 !== peg$FAILED) {
            while (s2 !== peg$FAILED) {
                s1.push(s2);
                if (peg$r3.test(input.charAt(peg$currPos))) {
                    s2 = input.charAt(peg$currPos);
                    peg$currPos++;
                }
                else {
                    s2 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e21);
                    }
                }
            }
        }
        else {
            s1 = peg$FAILED;
        }
        if (s1 !== peg$FAILED) {
            peg$savedPos = s0;
            s1 = peg$f12();
        }
        s0 = s1;
        return s0;
    }
    function peg$parseString() {
        var s0, s1, s2, s3;
        s0 = peg$currPos;
        if (input.charCodeAt(peg$currPos) === 39) {
            s1 = peg$c18;
            peg$currPos++;
        }
        else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e22);
            }
        }
        if (s1 !== peg$FAILED) {
            s2 = [];
            if (peg$r4.test(input.charAt(peg$currPos))) {
                s3 = input.charAt(peg$currPos);
                peg$currPos++;
            }
            else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e23);
                }
            }
            while (s3 !== peg$FAILED) {
                s2.push(s3);
                if (peg$r4.test(input.charAt(peg$currPos))) {
                    s3 = input.charAt(peg$currPos);
                    peg$currPos++;
                }
                else {
                    s3 = peg$FAILED;
                    if (peg$silentFails === 0) {
                        peg$fail(peg$e23);
                    }
                }
            }
            if (input.charCodeAt(peg$currPos) === 39) {
                s3 = peg$c18;
                peg$currPos++;
            }
            else {
                s3 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e22);
                }
            }
            if (s3 !== peg$FAILED) {
                peg$savedPos = s0;
                s0 = peg$f13(s2);
            }
            else {
                peg$currPos = s0;
                s0 = peg$FAILED;
            }
        }
        else {
            peg$currPos = s0;
            s0 = peg$FAILED;
        }
        return s0;
    }
    function peg$parseParamSeparator() {
        var s0;
        if (input.charCodeAt(peg$currPos) === 44) {
            s0 = peg$c19;
            peg$currPos++;
        }
        else {
            s0 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e24);
            }
        }
        return s0;
    }
    function peg$parse_() {
        var s0, s1;
        peg$silentFails++;
        s0 = [];
        if (input.charCodeAt(peg$currPos) === 32) {
            s1 = peg$c20;
            peg$currPos++;
        }
        else {
            s1 = peg$FAILED;
            if (peg$silentFails === 0) {
                peg$fail(peg$e26);
            }
        }
        while (s1 !== peg$FAILED) {
            s0.push(s1);
            if (input.charCodeAt(peg$currPos) === 32) {
                s1 = peg$c20;
                peg$currPos++;
            }
            else {
                s1 = peg$FAILED;
                if (peg$silentFails === 0) {
                    peg$fail(peg$e26);
                }
            }
        }
        peg$silentFails--;
        s1 = peg$FAILED;
        if (peg$silentFails === 0) {
            peg$fail(peg$e25);
        }
        return s0;
    }
    peg$result = peg$startRuleFunction();
    if (peg$result !== peg$FAILED && peg$currPos === input.length) {
        return peg$result;
    }
    else {
        if (peg$result !== peg$FAILED && peg$currPos < input.length) {
            peg$fail(peg$endExpectation());
        }
        throw peg$buildStructuredError(peg$maxFailExpected, peg$maxFailPos < input.length ? input.charAt(peg$maxFailPos) : null, peg$maxFailPos < input.length
            ? peg$computeLocation(peg$maxFailPos, peg$maxFailPos + 1)
            : peg$computeLocation(peg$maxFailPos, peg$maxFailPos));
    }
}
export { peg$SyntaxError as SyntaxError, peg$parse as parse };
