import { CollectionService } from "./CollectionService.js";
import { ModelService } from "./ModelService.js";
/**
 * Service class for managing View Groups
 */
export class ViewGroupService extends CollectionService {
    modelService;
    constructor(dbHelper, clientId) {
        super(dbHelper, "view_groups", clientId);
        this.modelService = new ModelService(dbHelper, clientId);
    }
    /**
     * List all View Groups
     * @returns Array of view groups
     */
    async list(options = {}) {
        const viewGroups = await super.list(options);
        return viewGroups;
    }
    /**
     * Get a View Group by ID
     * @param viewGroupId ID of the view group to retrieve
     * @returns The requested view group
     */
    async get(viewGroupId) {
        const viewGroup = await super.get(viewGroupId);
        if (!viewGroup)
            return null;
        return viewGroup;
    }
}
export default ViewGroupService;
