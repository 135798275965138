automation:
  add_step: Schritt hinzufügen
  add_trigger: Fügen Sie einen Auslöser hinzu
  create_new: Neue Automatisierung
  error_launch: Der Automatisierungsstart ist fehlgeschlagen
  select_trigger_description: >-
    Wählen Sie einen Auslöser aus, um mit der Konfiguration Ihrer
    Automatisierung zu beginnen
  success_launch: Die Einführung der Automatisierung wurde erfolgreich abgeschlossen
  trigger_api: Wenn ein API-Aufruf empfangen wird
  trigger_graphquery: Wenn eine graphQL-Abfrage ausgeführt wird
  trigger_transformation: Wenn ein Objekt bearbeitet wird
automations:
  automation_name_placeholder: Automatisierungsname
  create_automation: Erstellen Sie eine neue Automatisierung
  creation_automation_name: Automatisierungsname
  modal_new_automation_description: >-
    Automatisierungen ermöglichen es Ihnen, automatisierte Prozesse zu
    erstellen, um Daten in Bonx zu erstellen, zu bearbeiten und zu speichern.
  run:
    datetime: Laufdatum
    id: Ich würde laufen
  step:
    end_datetime: Enddatum des Schritts
    name: Schrittname
    start_datetime: Startdatum des Schritts
  tab_detail: Automatisierung
  tab_history: Geschichte
blocks:
  qrCodeFullSize: QR-Code-Schaltfläche im Vollbildmodus
button:
  about: Um
  back: Der Rücken
  toggle_dark: Dunkler Modus
  toggle_langs: Sprache umschalten
dashboard:
  last_30_days: Letzte 30 Tage
data_connection:
  add_step: Schritt hinzufügen
  auth: Auth-Methode
  auth_basic: Basic
  data_connection_name_placeholder: Meine Datenverbindung
  data_connection_step_type_placeholder: Platzhalter für Datenverbindungsschritte
  data_connection_steps: Schrittdetail
  document_url: Gemeinsame URL des Google-Blatts
  input_data: Eingabevariable
  json_edition_label: Bearbeiten Sie data_connection json
  name: Schrittname
  password: Passwort
  query: Anfrage
  save_file: Datei gespeichert
  save_output: Ausgangsvariable
  step_json_edition_label: Bearbeiten Sie den Schritt json
  tab: Ausgewählter Google Sheet-Tab
  url: API-URL
  username: Nutzername
email:
  body: Nachrichtentext
  subject: Betreff E-Mail
filters:
  operators_doesnt_include: Enthält nicht
  operators_equals_to: (==) gleich
  operators_greater_than: (>) größer als
  operators_greater_than_or_equal_to: (>=) größer oder gleich
  operators_includes: Enthält
  operators_is_empty: ist leer
  operators_is_not_empty: ist nicht leer
  operators_less_than: (<) kleiner als
  operators_less_than_or_equal_to: (<=) kleiner oder gleich
  operators_no_operator: Keine Bedingung
  operators_not_equal_to: (!=) ungleich
form.dollar_helper: >-
  Variablen, denen ein „$“-Zeichen vorangestellt ist, sind formularintern. 

  Diese Variablen müssen dann mit den unten stehenden Modellattributen verknüpft
  werden.
form_field_max_value: Maximalwert
forms:
  action_delete_form: Formular löschen
  action_dupplicate_form: Formular duplizieren
  autofill_end: >-
    Dieses Feld wird automatisch von Bonx ausgefüllt, wenn das Formular gesendet
    wird, wobei das aktuelle Datum und die aktuelle Uhrzeit verwendet werden.
  autofill_information: Dieses Feld wird von Bonx automatisch ausgefüllt
  autofill_start: >-
    Dieses Feld wird automatisch von Bonx ausgefüllt, wenn das Formular geöffnet
    wird, unter Verwendung des aktuellen Datums und der Uhrzeit.
  base_model: Basismodell
  create_form: Formular erstellen
  create_new: Neue Form
  creation_form_name: Formularname
  deletion_confirmation_body: |-
    Möchten Sie dieses Formular wirklich löschen? 
    Nur ein Bonx-Administrator kann es in Zukunft wiederherstellen.
  deletion_confirmation_title: Löschen des Formulars
  edit_no_field_warning: Dieses Formular hat derzeit kein Feld
  error_save_not_admin: Nur ein Administrator kann ein bearbeitetes Formular speichern
  field_multiple_answers_allowed: Hier können Sie mehrere Optionen auswählen
  field_no_options: Es sind noch keine Optionen verfügbar.
  form_field_autocomplete: Automatische Vervollständigung
  form_field_date: Datum
  form_field_display: Anzeige
  form_field_display_readquery_placeholder: Fügen Sie eine sekundäre Leseabfrage hinzu (optional)
  form_field_document: Dokumentieren
  form_field_has_min_max_values: Hat Min-Max-Werte
  form_field_image: Bild
  form_field_label_label: Feldname
  form_field_min_value: Mindestwert
  form_field_multiple_choice: Mehrere Auswahlmöglichkeiten
  form_field_name_label: Attributschlüssel
  form_field_number: Nummer
  form_field_option: Möglichkeit
  form_field_option_required: >-
    Ein Optionsfeld ist standardmäßig erforderlich, bitte wählen Sie das Feld
    "Mehrfachauswahl", wenn vom Formular ein leerer Wert zurückgegeben werden
    kann.
  form_field_options_label: Auswahlliste
  form_field_options_placeholder: Fügen Sie eine Auswahl hinzu
  form_field_printable_form: ''
  form_field_read_query: Abfrage lesen
  form_field_required_label: Obligatorisch
  form_field_text: Textfeld
  form_field_text_description_placeholder: Fügen Sie hier eine Beschreibung oder einen Helfer hinzu (optional)
  form_field_text_label_placeholder: Fügen Sie einen Feldnamen hinzu
  form_field_text_name_placeholder: Fügen Sie einen Attributnamen hinzu
  form_field_text_value_placeholder: Fügen Sie einen Standardwert hinzu (optional)
  form_field_textarea_label: Langer Text
  form_field_write_query: Anfrage schreiben
  form_fields: Formularfelder
  form_readquery_placeholder: Fügen Sie eine primäre Leseabfrage hinzu (optional)
  form_title_placeholder: Formularname
  form_writequery_placeholder: Fügen Sie eine Schreibabfrage hinzu
  formreadquery_placeholder: Fügen Sie eine primäre Leseabfrage hinzu (optional)
  modal_new_form_description: >-
    Mit dem Formularersteller können Sie Formulare erstellen, die von Ihren
    Teams ausgefüllt werden, und damit das Datenfeedback automatisieren.
  modal_share_form_description: >-
    Sie können dieses Formular per E-Mail mit einem eindeutigen Link teilen und
    öffentlich zugänglich.
  no_related_model_found: Kein Modell gefunden
  share: Teilen
  share_form_emails_label: E-mailadressen
  toast_deletion_success: Dieses Formular wurde erfolgreich gelöscht.
  toast_duplication_success: |-
    Das Formular wurde erfolgreich dupliziert. 
    Sie werden zu diesem neuen Formular weitergeleitet.
forms.choose_attribute: Attribut auswählen
forms.creation_import_fields: Modellattribute importieren
forms.creation_related_model: Verwandtes Modell
forms.creation_related_process: Verwandter Prozess
forms.creation_related_process_step: Zugehöriger Prozessschritt
forms.form_field_no_related_model_placeholder: Kein verwandtes Modell
forms.form_field_no_relationship_placeholder: Keine Beziehung
forms.form_field_relation: Beziehung
forms.form_field_relationship_allow_existing_object_label: Verknüpfung eines bestehenden Objekts zulassen
forms.form_field_relationship_allow_new_object_label: Erlauben Sie die Erstellung eines neuen verknüpften Elements
forms.form_field_relationship_related_model_label: Wählen Sie ein entsprechendes Modell aus
forms.form_field_relationship_value_label: Wählen Sie eine Beziehung aus
forms.form_share: Öffentlich geteiltes Formular
forms.labels: Etiketten
forms.new_form_advanced: Fortschrittlich
forms.new_form_classic: Klassisch
forms.no_model: Dieses Formular ist nicht mit einem bestehenden Modell verknüpft
forms.no_related_step: Nicht mit einem Prozessschritt verknüpft
global:
  actions: Aktionen
  add: Hinzufügen
  automations: Automatisierungen
  cancel: Absagen
  click_button_reload: Bitte klicken Sie auf die Schaltfläche unten, um neu zu laden!
  client: Unternehmen
  client_name: Kundenname
  clients: Kunden
  close: Schließen
  collaboration: Zusammenarbeit
  comment: Kommentar
  compare: Vergleichen
  create: Schaffen
  currency: Währung
  dashboard: Armaturenbrett
  data: Daten
  data_connections: Datenverbindungen
  data_saved: Ihre Daten wurden gespeichert
  days: Tage
  delete: Löschen
  description: Beschreibung
  disabled: Behindert
  disabled_users: Deaktivierte Benutzer
  display: Anzeige
  duplicate: Duplizieren
  duration: Dauer
  edit: Bearbeiten
  email: Email
  entity: Einheit
  error: Fehler
  error_json: JSON ist falsch
  error_login_invalid_password: Falsches Passwort.
  error_login_invalid_user: Benutzer nicht gefunden, bitte überprüfen Sie den Benutzernamen.
  error_login_unknown: >-
    Unbekannter Protokollierungsfehler, bitte wenden Sie sich über die runde
    Schaltfläche unten rechts auf diesem Bildschirm an das Bonx-Team.
  error_password_validation: >-
    Ihr Passwort muss mindestens 8 Zeichen lang sein und mindestens einen
    Kleinbuchstaben, einen Großbuchstaben und eine Zahl enthalten.
  failed: Fehlgeschlagen
  file_input:
    no_file: Sie haben noch keine Datei eingereicht
  filter: Filter
  first_name: Vorname
  follow_up: Nachverfolgen
  forgot_password: Passwort vergessen
  forms: Formen
  from: Aus
  groups: Gruppen
  home: Heim
  id: Id
  is_required: ist nötig
  label: Etikett
  labels: |-
    Etikett | 
    Etikett | 
    Etiketten
  large: Groß
  last_name: Familienname, Nachname
  launch: Start
  login: Anmeldung
  logout: Ausloggen
  medium: Mittel
  missing_write_request: Sie haben keine Schreibanfrage gestellt
  models: |-
    Modell | 
    Modell | 
    Modelle
  month:
    april_short: Apr
    august_short: Aug
    december_short: Dez
    february_short: Feb
    january_short: jan
    july_short: Juli
    june_short: jun
    march_short: beschädigen
    may_short: kann
    november_short: Nov
    october_short: Okt
    september_short: Sept
  name: Name
  new: Neu
  new_version: Eine neue Version ist verfügbar!
  not-found: Nicht gefunden
  objects: |-
    Objekt | 
    Objekt | 
    Objekte
  order: Befehl
  overview: Überblick
  parameters: Parameter
  password: Passwort
  phone: Telefon
  photo: Foto
  photo_url: URL-Foto
  process: Verfahren
  project: Projekt
  projects: Projekte
  reload: Neu laden
  remember_me: Mich erinnern
  reset_email_sent: >-
    Eine E-Mail zum Zurücksetzen Ihres Passworts wurde gesendet. Bitte klicken
    Sie auf den Link, wenn Sie ihn erhalten haben
  reset_password: Passwort zurücksetzen
  role: Rolle
  rows: Reihen
  save: Speichern
  saving: Sparen...
  search: Suche
  send: Senden
  send_reset_email: E-Mail zum Zurücksetzen senden
  settings: Einstellungen
  show: Zeigen
  small: Klein
  status: Status
  success: Erfolg
  table:
    nav: Paginierung der Tabelle
  task: Aufgabe | Aufgabe | Aufgaben
  total_report: Gesamtbericht
  training_and_help: Ressourcenzentrum
  type: Typ
  unsinchronized_store_header_content: |-
    Das folgende Element wurde aktualisiert. 
    Daher können Sie keine Ausgabe speichern, bevor Sie die Seite aktualisieren.
  unsinchronized_store_header_content_pt1: Die folgenden Elemente wurden aktualisiert
  unsinchronized_store_header_content_pt2: >-
    Daher dürfen Sie keine Bearbeitung vornehmen, bevor Sie die Seite
    aktualisieren
  unsinchronized_store_header_title: Die Bonx-Konfiguration wurde von einem anderen Benutzer bearbeitet
  update: Aktualisieren
  user: Benutzer
  users: Benutzer
  users_and_permissions: Benutzer und Berechtigungen
  validate: Bestätigen
  value: Wert
  views: |-
    Anzeigen | 
    Anzeigen | 
    Ansichten
  week: Woche
  you_dont_have_access_to_this_page: Sie haben keinen Zugriff auf diese Seite
global.attribute: Attribut
global.instance: Beispiel
global.model: Modell
global.toast_key_copy_success_content: Der Schlüssel wurde erfolgreich in Ihre Zwischenablage kopiert.
global.toast_succes_copy_to_clipboard: Der Tabelleninhalt wurde erfolgreich in Ihre Zwischenablage kopiert
global.update: Aktualisieren
groups:
  group_name: Name der Gruppe
  group_order: Gruppenbestellung
  groups_list: Gruppenliste
  toast_edit_success: In der Gruppe gespeicherte Änderungen wurden erfolgreich gespeichert
home:
  greetings: Grüße
modal_confirmation_popup_cancel: Nein
modal_confirmation_popup_confirm: Ja
object:
  attribute:
    attributes: |-
      Attribut | 
      Attribut | 
      Attribute
  attributes: Objektattribute
  attributes_list: Liste der Attribute
  error_attribute_with_same_name_exists: Ein vorhandenes Attribut hat bereits denselben Namen
  error_loading: Beim Laden des Inhalts dieser Seite ist ein Fehler aufgetreten
  error_no_primary_attribute: Für dieses Modell wurde kein primäres Attribut definiert
  related_objects: |-
    Zugehöriges Objekt | 
    Zugehöriges Objekt | 
    Verwandte Objekte
  relation:
    attributes_list: Liste der Beziehungsattribute
  relationship:
    object: Verknüpftes Objekt
    to_object: Zum Objekt
  relationship_list: Beziehungsliste
  relationships: |-
    Beziehung | 
    Beziehung | 
    Beziehungen
  selected_setting_object: Objekttyp
objects:
  add_attribute: Fügen Sie ein Attribut hinzu
  add_label: Erstellen Sie ein Etikett
  add_object: Erstellen Sie ein Objekt
  attributes_subtype_float: Gleitkommazahl
  attributes_subtype_integer: Ganzzahl
  attributes_subtype_link: Verknüpfung
  attributes_subtype_plain_text: Klartext
  data_model: Datenmodell
  data_models: Datenmodelle
  edit_attribute: Bearbeiten Sie ein Attribut
  edit_label: Bearbeiten Sie ein Etikett
  edit_object: Bearbeiten Sie ein Objekt
  label:
    placeholder: Bitte wählen Sie ein Etikett aus
  label_objects: Mit diesem Label verknüpfte Objekte
  label_relations: Beziehungen, die mit diesem Label verknüpft sind
  labels: Etiketten
  labels_list: Etikettenliste
  models: Modelle
  models_list: Modelllisten
  objects_list: Objektliste
  overview: Überblick
  relation:
    add_relation: Fügen Sie eine Beziehung hinzu
    edit_relation: Bearbeiten Sie eine Beziehung
  relations: Beziehungen
otable:
  error:
    table_has_no_column: >-
      Diese Tabelle wird sichtbar, sobald ein Schlüssel als Spalte hinzugefügt
      wird, bitte wenden Sie sich an einen Administrator Ihres Unternehmens.
pages:
  automation: Automatisierung
  data-connection: Datenverbindung
  object: Objekt
process.finished: Fertig
process.in_progress: Im Gange
process.waiting: Warten
processes:
  next_step: Nächster Schritt
  step_name: Schrittname
profile:
  gantt_size_label: Gantt-Diagrammanzeige
  home_page: Startseite
  my_profile: Mein Profil
  settings: Einstellungen
timeline:
  external_user: Externer Benutzer
toast:
  form_submit_content: Das Formular wurde erfolgreich übermittelt
  print_success: Eine Seite wird bald geöffnet
  view_error_no_model_title: Mit Bonx können Sie bald komplexe Ansichten bearbeiten
  view_error_no_model_title_content: >-
    Diese Ansicht wurde manuell erstellt, bitte wenden Sie sich an Ihre
    IT-Abteilung oder einen Bonx-Administrator.
toast.form_field_file_submit_content_error: Für dieses Formular muss eine Datei eingereicht werden.
user:
  add_label: Erstellen Sie ein Etikett
  data_model:
    labels: Etiketten
    models: Modelle
    relations: Beziehungen
  data_models: Datenmodelle
  edit_label: Bearbeiten Sie ein Etikett
  label:
    placeholder: Finden Sie ein Etikett ...
  models_list: Etikettenliste
view:
  action_move_up: Bewegen Sie sich nach oben
  no_title: Kein Titel
  toast_edit_success: Das Ansichtsdetail wurde erfolgreich bearbeitet
views:
  action_move_down: Sich abwärts bewegen
  add_attribute: Neues Attribut
  add_block: Neuer Block
  block_name: Blockname
  block_type: Geben Sie den Block ein
  create_new_view: Erstellen Sie eine neue Ansicht
  create_new_view_description: >-
    Ansichten ermöglichen es Ihnen, die Daten, an denen Sie interessiert sind,
    in verschiedenen Formen zu visualisieren und mit Ihren Teams zu teilen.
  create_view: Erstellen Sie eine neue Ansicht
  creation_automation_name_label: Automatisierungsname
  creation_form_name_label: Formularname
  creation_view_model: Verwandtes Modell
  creation_view_name: Name der Ansicht
  default_setup_element_name: Daten zum Modell
  default_view_group_name: Standardgruppe
  deletion_confirmation_body: >-
    Möchten Sie diese Ansicht wirklich löschen? 

    Alle Daten werden gespeichert, falls Sie einen Fehler gemacht haben und dies
    rückgängig machen möchten.
  deletion_confirmation_title: Löschen anzeigen
  edit_attributes_options: Attributoptionen
  edit_attributes_options_add_colors: Farben
  edit_attributes_options_add_icon: Symbole
  edit_attributes_options_add_status: Status
  edit_attributes_options_background_color: Hintergrundfarbe
  edit_attributes_options_border_color: Randfarbe
  edit_attributes_options_description: >-
    Wenn Sie die Optionen eines Attributs auswählen, können Sie seine tiefen
    Einstellungen ändern.
  edit_attributes_options_hide_json: Details ausblenden
  edit_attributes_options_icon: Symbol
  edit_attributes_options_no_attributes: Dieser Block besitzt noch kein Attribut.
  edit_attributes_options_show_json: Zeige Details
  edit_attributes_options_status_error: 'Zustand: Fehler'
  edit_attributes_options_status_success: 'Zustand: Erfolg'
  edit_attributes_options_status_warning: 'Zustand: Warnung'
  edit_import_model: Importieren Sie ein Modell
  edit_import_model_description: >-
    Durch das Importieren eines Modells können Sie mit einem Klick alle seine
    Attribute als Achsen des Blocks hinzufügen.
  edition_deletion: Ansicht löschen
  edition_no_columns: >-
    Diese Kontrollkästchen werden sichtbar, sobald ein Attribut zum Modell
    hinzugefügt wird. Bitte wenden Sie sich an einen Administrator Ihres
    Unternehmens.
  edition_no_related_objects: Dieses Modell ist mit keinem anderen Modell verwandt
  edition_primary_table: Primäre Datentabelle
  edition_secondary_related_objects: Verwandte Objekte
  edition_selected_columns: Säulen
  error:
    query_invalid_or_missing: Die bereitgestellte Anfrage ist leer oder führte zu einem Fehler.
  filter_label_placeholder: Label zum Filtern
  filter_value_placeholder: Wert
  filter_view: Filtern Sie diese Ansicht
  filters_add_filter: Fügen Sie eine Bedingung hinzu
  gantt:
    bar:
      no_label: Dieser Barren besitzt kein Etikett
    precision:
      day: Tage
      display_by: Anzeigen von
      month: Monate
  id: Id
  label: Etikett
  modal_new_view_description: >-
    Ansichten ermöglichen es Ihnen, Ihre Daten so zu visualisieren, wie Sie es
    möchten, und sie mit Ihren Teams zu teilen.
  query: GraphQL-Anfrage
  toast_deletion_success: Diese Ansicht wurde erfolgreich gelöscht.
  toast_edit_success: Die Ansicht wurde erfolgreich bearbeitet
  type: Typ
  view_block_name_placeholder: Mein Ansichtsblockname (optional)
  view_name_placeholder: Namen anzeigen
  view_parameters_title: Parameter anzeigen
  views_list: Liste der Aufrufe
