/*
The goal of this file is to manage the merge between an ordered list
that comes from core containing a customId field
and an ordered list that comes from user embedding core + customization
to manage this merge we use a linked list that is stored in the database and which will allow us to reconstruct the modified list
from the core list and the linked list

disclaimer : customization handle ONLY the insertion of new element in the base core list
*/
import { has } from "lodash-es";
import { LinkedList, Node } from "./LinkedList.js";
const IS_CUSTOM_FLAG = "__IS_CUSTOM";
/**
 * create a linked list that will track the modification made by the user
 * @param core immutable core list
 * @param input list modified by the user (includes core list)
 * @param idField field used to identify the id of elements int the list
 * @returns linked list that will track the modification made by the user
 */
function createCustomTrackerLinkedList(core, input, idField) {
    const changesTrackerLinkedList = LinkedList.fromList(core, idField);
    const coreToObject = core.reduce((acc, item) => {
        if (typeof item === "object" && item !== null && idField in item) {
            const key = item[idField];
            if (typeof key === "string" || typeof key === "number")
                acc[key] = item;
        }
        return acc;
    }, {});
    // check input list has idField for each element
    for (const item of input) {
        if (typeof item !== "object" || item === null)
            throw new Error(`Invalid item type: ${JSON.stringify(item)}`);
        if (!(idField in item))
            throw new Error(`Missing ${idField} for element ${JSON.stringify(item)}`);
        const typedItem = item;
        if (typeof typedItem[idField] !== "string" && typeof typedItem[idField] !== "number")
            throw new Error(`Invalid ${idField} type for element ${JSON.stringify(item)}`);
    }
    const inputListToObject = input.reduce((acc, item, idx) => {
        acc[idx] = item;
        return acc;
    }, {});
    for (const [index, content] of Object.entries(inputListToObject)) {
        const key = Number.parseInt(index);
        // check if content is in core
        if (coreToObject[content[idField]])
            continue;
        // content is not in core
        const currentItem = content;
        const currentId = currentItem[idField];
        let prevId = null;
        let nextId = null;
        if (key > 0 && inputListToObject[key - 1]) {
            const prev = inputListToObject[key - 1];
            prevId = prev[idField];
        }
        if (key < input.length - 2) {
            const next = inputListToObject[key + 1];
            nextId = next[idField];
        }
        changesTrackerLinkedList.insertBetween(prevId, nextId, new Node(currentId, { ...content, [IS_CUSTOM_FLAG]: true }));
    }
    const nodesWithoutCore = LinkedList.filter(changesTrackerLinkedList, node => has(node.content, IS_CUSTOM_FLAG));
    // cleanup flag
    const nodesWithoutFlagAndFlatContent = nodesWithoutCore.map((node) => {
        const { [IS_CUSTOM_FLAG]: _, ...contentWithoutFlag } = node.content;
        return { ...contentWithoutFlag, prev: node.prev, next: node.next };
    });
    return nodesWithoutFlagAndFlatContent;
}
/**
 * rebuild the original list modified by user from immutable core list and custom list stored in database
 * @param core immutable core list
 * @param customList custom list stored in database
 * @param idField field used to identify the id of elements int the list
 * @returns the original list modified by user (merged from core and custom list)
 */
function buildListFromCoreAndCustom(core, customList, idField) {
    const linkedList = LinkedList.fromList(core, idField);
    if (customList) {
        for (const item of customList)
            linkedList.insertBetween(item.prev, item.next, new Node(item[idField], item));
    }
    return linkedList.getNodesAsArray().map(node => node.content);
}
export { createCustomTrackerLinkedList, buildListFromCoreAndCustom };
